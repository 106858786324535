import * as React from "react"
import { graphql, Link } from "gatsby"
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { linkResolver } from '../../../../utils/link_resolver'
import Seo from '../../../../components/seo'
import Footer from '../../../../components/footer'
import Header from '../../../../components/header'
import Helper from '../../../../components/helper'
import HelperSizeCatCollars from '../../../../components/helper_size_cat_collars'
import CartContext from '../../../../components/cart_context';
import ProductQuantity from '../../../../components/product_quantity';
import { useState, useContext, useEffect } from 'react'
import ProductSafety from "../../../../components/product_safety"
import ProductHeader from "../../../../components/product_header"
import ProductCustomHeader from "../../../../components/product_custom_header"
import Breadcrumb from "../../../../components/breadcrumb"

const CatsCollarsBreakawaySafety = ({data}) => {
  
  const entry = data.prismicCatsCollarsBreakawaySafety
  const {getProductById} = useContext(CartContext);

  const [product, setProduct] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [variantId, setVariantId] = useState(null);
  const [selectedSize, setSelectedSize] = useState(data.shopifyProduct.options[0].values[0])
  
  let custom_strap_colour = getStockLevels(entry.data.custom_strap_colour, 'custom_strap_colour');
  const [selectedStrapColour, setSelectedStrapColour] = useState(custom_strap_colour[0].name)
  const [selectedStrapColourImage, setSelectedStrapColourImage] = useState(custom_strap_colour[0])
  
  let custom_strap_keeper_colour = getStockLevels(entry.data.custom_strap_keeper_colour, 'custom_strap_keeper_colour');
  const [selectedStrapKeeperColour, setSelectedStrapKeeperColour] = useState(custom_strap_keeper_colour[0].name)
  const [selectedStrapKeeperColourImage, setSelectedStrapKeeperColourImage] = useState(custom_strap_keeper_colour[0])
  
  let custom_breakaway_buckle_colour = getStockLevels(entry.data.custom_breakaway_buckle_colour, 'custom_breakaway_buckle_colour');
  const [selectedBreakawayBuckleColour, setSelectedBreakawayBuckleColour] = useState(custom_breakaway_buckle_colour[0].name)
  const [selectedBreakawayBuckleColourImage, setSelectedBreakawayBuckleColourImage] = useState(custom_breakaway_buckle_colour[0])
  
  let custom_metal_hardware = getStockLevels(entry.data.custom_metal_hardware, 'custom_metal_hardware');
  const [selectedMetalHardware, setSelectedMetalHardware] = useState(custom_metal_hardware[0].name)
  const [selectedMetalHardwareImage, setSelectedMetalHardwareImage] = useState(custom_metal_hardware[0])

  let custom_embellishments = getStockLevels(entry.data.custom_embellishments, 'custom_embellishments');
  const [selectedEmbellishmentsColour, setSelectedEmbellishmentsColour] = useState(custom_embellishments[0].name)
  const [selectedEmbellishmentsImage, setSelectedEmbellishmentsImage] = useState(custom_embellishments[0])
  
  const [selectedEmbellishmentsOption, setSelectedEmbellishmentsOption] = useState(data.shopifyProduct.options[1].values[0])
  const [selectedEmbellishments, setSelectedEmbellishments] = useState('No')

  const [formValues, setFormValues] = useState({
    formErrors: '',
    formIsValid: true,
  });
  const [customSelections, setCustomSelections] = useState([
    {
      key: "Strap Colour",
      value: selectedStrapColour
    },
    {
      key: "Strap Keeper Colour",
      value: selectedStrapKeeperColour
    },
    {
      key: "Breakaway Buckle Colour",
      value: selectedBreakawayBuckleColour
    },
    {
      key: "Metal Hardware",
      value: selectedMetalHardware
    },
    {
      key: "Embellishments",
      value: selectedEmbellishments
    }
  ])

  useEffect(() => {
    getProductById(data.shopifyProduct.storefrontId).then(result => {
      setProduct(result);
      if (result) {
        setSelectedVariant(
          result.variants.find(({ id }) => id === variantId) || result.variants[0]
        );
      }
    });
  }, [
    getProductById,
    setProduct,
    data.shopifyProduct.storefrontId,
    variantId,
    selectedStrapColour,
    selectedStrapColourImage,
    selectedStrapKeeperColour,
    selectedStrapKeeperColourImage,
    selectedBreakawayBuckleColour,
    selectedBreakawayBuckleColourImage,
    selectedMetalHardware,
    selectedMetalHardwareImage,
    selectedEmbellishments,
    selectedEmbellishmentsColour,
    selectedEmbellishmentsImage,
    customSelections
  ]);

  function getStockLevels(data, component, currentSelectedSize) {
    if (!currentSelectedSize) {
      currentSelectedSize = selectedSize;
    }
    const newStock = data.filter(colour => {
      return !entry.data.out_of_stock.some(stock => {
        if (stock.size === currentSelectedSize && stock.component === component) {
          return stock.name === colour.name;
        }
        return false;
      });
    });
    return newStock;
  }

  function newCustomSelections(component, name, prevCustomSelections) {
    const newCustomSelections = prevCustomSelections.map(customSelection => {
      if (customSelection.key === component) {
        return {...customSelection, value: name};
      }
      return customSelection;
    });
    return newCustomSelections;
  }
  
  const handleVariantSizeChange = e => {
    setSelectedSize(e.target.id);
    let currentSelectedSize = e.target.id

    let selectedVariantChange = `${e.target.id} / ${selectedEmbellishmentsOption} / ${selectedMetalHardware}`;
    let newVariant = data.shopifyProduct?.variants.find(
      variant => variant.title === selectedVariantChange
    );
    setVariantId(newVariant.shopifyId);

    custom_strap_colour = getStockLevels(entry.data.custom_strap_colour, 'custom_strap_colour', currentSelectedSize);
    setSelectedStrapColour(custom_strap_colour[0].name)
    setSelectedStrapColourImage(custom_strap_colour[0])
    setCustomSelections(prevCustomSelections => newCustomSelections('Strap Colour', custom_strap_colour[0].name, prevCustomSelections));
    
    custom_strap_keeper_colour = getStockLevels(entry.data.custom_strap_keeper_colour, 'custom_strap_keeper_colour', currentSelectedSize);
    setSelectedStrapKeeperColour(custom_strap_keeper_colour[0].name)
    setSelectedStrapKeeperColourImage(custom_strap_keeper_colour[0])
    setCustomSelections(prevCustomSelections => newCustomSelections('Strap Keeper Colour', custom_strap_keeper_colour[0].name, prevCustomSelections));

    custom_breakaway_buckle_colour = getStockLevels(entry.data.custom_breakaway_buckle_colour, 'custom_breakaway_buckle_colour', currentSelectedSize);
    setSelectedBreakawayBuckleColour(custom_breakaway_buckle_colour[0].name)
    setSelectedBreakawayBuckleColourImage(custom_breakaway_buckle_colour[0])
    setCustomSelections(prevCustomSelections => newCustomSelections('Breakaway Buckle Colour', custom_breakaway_buckle_colour[0].name, prevCustomSelections));

    custom_metal_hardware = getStockLevels(entry.data.custom_metal_hardware, 'custom_metal_hardware', currentSelectedSize);
    setSelectedMetalHardware(custom_metal_hardware[0].name)
    setSelectedMetalHardwareImage(custom_metal_hardware[0])
    setCustomSelections(prevCustomSelections => newCustomSelections('Metal Hardware', custom_metal_hardware[0].name, prevCustomSelections));

    custom_embellishments = getStockLevels(entry.data.custom_embellishments, 'custom_embellishments', currentSelectedSize);
    setSelectedEmbellishmentsColour(custom_embellishments[0].name)
    setSelectedEmbellishmentsImage(custom_embellishments[0])
    if (selectedEmbellishmentsOption !== 'No') {
      setCustomSelections(prevCustomSelections => newCustomSelections('Embellishments', custom_embellishments[0].name, prevCustomSelections));
    }
  };

  const handleStrapColourChange = e => 
  {
    setSelectedStrapColour(e.target.value);
    setCustomSelections(prevCustomSelections => newCustomSelections('Strap Colour', e.target.value, prevCustomSelections));
    const newSelectedStrapColourImage = entry.data.custom_strap_colour.find(image => (
      image.name === e.target.value
    ))
    setSelectedStrapColourImage(newSelectedStrapColourImage)
  };

  const handleStrapKeeperColourChange = e => 
  {
    setSelectedStrapKeeperColour(e.target.value);
    setCustomSelections(prevCustomSelections => newCustomSelections('Strap Keeper Colour', e.target.value, prevCustomSelections));
    const newImage = entry.data.custom_strap_keeper_colour.find(image => (
      image.name === e.target.value
    ))
    setSelectedStrapKeeperColourImage(newImage)
  };

  const handleBreakawayBuckleColourChange = e => 
  {
    setSelectedBreakawayBuckleColour(e.target.value);
    setCustomSelections(prevCustomSelections => newCustomSelections('Breakaway Buckle Colour', e.target.value, prevCustomSelections));
    const newImage = entry.data.custom_breakaway_buckle_colour.find(image => (
      image.name === e.target.value
    ))
    setSelectedBreakawayBuckleColourImage(newImage)
  };

  const handleMetalHardwareChange = e => {
    setSelectedMetalHardware(e.target.value);

    let selectedVariantChange = `${selectedSize} / ${selectedEmbellishmentsOption} / ${e.target.value}`
    let newVariant = data.shopifyProduct?.variants.find(
      variant => variant.title === selectedVariantChange
    )
    setVariantId(newVariant.shopifyId);

    setSelectedMetalHardware(e.target.value);
    setCustomSelections(prevCustomSelections => newCustomSelections('Metal Hardware', e.target.value, prevCustomSelections));
    const newImage = entry.data.custom_metal_hardware.find(image => (
      image.name === e.target.value
    ))
    setSelectedMetalHardwareImage(newImage)
  };

  const handleVariantEmbellishmentsOptionChange = e => {
    setSelectedEmbellishmentsOption(e.target.id);
    let selectedVariantChange = `${selectedSize} / ${e.target.id} / ${selectedMetalHardware}`

    let newVariant = data.shopifyProduct?.variants.find(
      variant => variant.title === selectedVariantChange
    )
    setVariantId(newVariant.shopifyId);

    if (e.target.id === 'No') {
      const newCustomSelections = customSelections.map(customSelection => {
        if (customSelection.key === 'Embellishments') {
          return {...customSelection, value: 'No'};
        }
        return customSelection;
      });
      setCustomSelections(newCustomSelections)
    }

    if (e.target.id === 'Yes (add $8)') {
      const newCustomSelections = customSelections.map(customSelection => {
        if (customSelection.key === 'Embellishments') {
          return {...customSelection, value: selectedEmbellishmentsImage.name};
        }
        return customSelection;
      });
      setCustomSelections(newCustomSelections)
      setSelectedEmbellishments(selectedEmbellishmentsImage.name)
    }
  };

  const handleEmbellishmentsChange = e => {
    setSelectedEmbellishmentsColour(e.target.id);
    const newCustomSelections = customSelections.map(customSelection => {
      if (customSelection.key === 'Embellishments') {
        return {...customSelection, value: e.target.id};
      }
      return customSelection;
    });
    setCustomSelections(newCustomSelections)

    const newSelectedEmbellishmentsImage = entry.data.custom_embellishments.find(image => (
      image.name === e.target.id
    ))
    setSelectedEmbellishmentsImage(newSelectedEmbellishmentsImage)
  };

  const currency = new Intl.NumberFormat('en-NZ', {
    style: 'currency',
    currency: 'NZD',
    minimumFractionDigits: 2
  })

  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <Header />
      <Breadcrumb 
        category="Cats" 
        category_url="cats"
        subcategory="Collars" 
        subcategory_url="collars"
        product={entry.data.heading} 
      />
      <ProductHeader data={entry.data} />
      <ProductCustomHeader props="Customise Yours" />

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/size_duo_white.png" className="w-24 h-24" alt="Strap" /> 
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex-none md:flex items-center justify-between pb-6">
                <div className="w-full md:w-8/12">
                  <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Choose size</h2>
                </div>
                <div className="w-full md:w-4/12 text-left md:text-right">
                  <HelperSizeCatCollars name="Size guide" data={data} />
                </div>
              </div>
              {!!selectedVariant && 
                <div>
                  <div className="sr-only">Choose size</div>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left md:text-center">
                    {data.shopifyProduct.options[0].values.map((name,i) => (
                      <label htmlFor={name} key={i} className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                        <input 
                          id={name}
                          defaultChecked={name === selectedSize}
                          type="radio"
                          value={name}
                          onChange={handleVariantSizeChange}
                          name="size"
                          className={name === selectedSize ? 'w-5 h-5 font-poppins text-lg bg-orange text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 text-lg bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                        />
                        {name}
                      </label>
                    ))}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/strap_white.png" className="w-28 h-28" alt="Strap" />  
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex-none md:flex items-center justify-between pb-6">
                <div className="w-full md:w-8/12">
                  <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Choose strap colour</h2>
                </div>
                <div className="w-full md:w-4/12 text-left md:text-right">
                  <Helper name="Strapping" data={entry.data.strapping} />
                </div>
              </div>
              {!!selectedVariant && 
                <div className="flex justify-between gap-6">
                  <div className="sr-only">Choose strap colour</div>
                  <div className="w-6/12 md:w-9/12">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left md:text-center">
                      {custom_strap_colour.map((custom,i) => (
                        <label htmlFor={`strap_colour_${custom.name}`} key={i} className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                          <input
                            id={`strap_colour_${custom.name}`}
                            checked={custom.name === selectedStrapColour}
                            type="radio"
                            value={custom.name}
                            onChange={handleStrapColourChange}
                            name="strap_colour"
                            style={{backgroundColor: `${custom.colour}`}}
                            className={custom.name === selectedStrapColour ? 'w-5 h-5 text-lg text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 text-lg bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                          />
                          {custom.name}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className="w-6/12 md:w-3/12">
                    <GatsbyImage className="object-fit w-full h-24 block" image={selectedStrapColourImage.image.gatsbyImageData} alt={selectedStrapColourImage.image.alt ?? ''} />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/strap_white.png" className="w-28 h-28" alt="Strap" />  
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex items-center justify-between pb-6">
                <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Choose strap keeper colour</h2>
              </div>
              {!!selectedVariant && 
                <div className="flex justify-between gap-6">
                  <div className="sr-only">Choose strap keeper colour</div>
                  <div className="w-6/12 md:w-9/12">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left md:text-center">
                      {custom_strap_keeper_colour.map((custom,i) => (
                        <label htmlFor={`strap_keeper_colour_${custom.name}`} key={i} className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                          <input
                            id={`strap_keeper_colour_${custom.name}`}
                            checked={custom.name === selectedStrapKeeperColour}
                            type="radio"
                            value={custom.name}
                            onChange={handleStrapKeeperColourChange}
                            name="strap_keeper_colour"
                            style={{backgroundColor: `${custom.colour}`}}
                            className={custom.name === selectedStrapKeeperColour ? 'w-5 h-5 text-lg text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 text-lg bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                          />
                          {custom.name}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className="w-6/12 md:w-3/12">
                    <GatsbyImage className="object-fit w-full h-24 block" image={selectedStrapKeeperColourImage.image.gatsbyImageData} alt={selectedStrapKeeperColourImage.image.alt ?? ''} />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/hardware_white.png" className="w-28 h-28" alt="Strap" />  
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex-none md:flex items-center justify-between pb-6">
                <div className="w-full md:w-8/12">
                  <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Choose buckle colour</h2>
                </div>
                <div className="w-full md:w-4/12 text-left md:text-right">
                  <Helper name="Hardware" data={entry.data.hardware} />
                </div>
              </div>
              {!!selectedVariant && 
                <div className="flex justify-between gap-6">
                  <div className="sr-only">Choose buckle colour</div>
                  <div className="w-6/12 md:w-9/12">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left">
                      {custom_breakaway_buckle_colour.map((custom,i) => (
                        <label htmlFor={`breakaway_buckle_${custom.name}`} key={i} className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                          <input
                            id={`breakaway_buckle_${custom.name}`}
                            checked={custom.name === selectedBreakawayBuckleColour}
                            type="radio"
                            value={custom.name}
                            onChange={handleBreakawayBuckleColourChange}
                            name="breakaway_buckle_colour"
                            style={{backgroundColor: `${custom.colour}`}}
                            className={custom.name === selectedBreakawayBuckleColour ? 'w-5 h-5 text-lg text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 text-lg bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                          />
                          {custom.name}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className="w-6/12 md:w-3/12">
                    <GatsbyImage className="object-fit block" image={selectedBreakawayBuckleColourImage.image.gatsbyImageData} alt={selectedBreakawayBuckleColourImage.image.alt ?? ''} />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/hardware_white.png" className="w-28 h-28" alt="Hardware" />  
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex-none md:flex items-center justify-between pb-6">
                <div className="w-full md:w-8/12">
                  <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Choose hardware</h2>
                </div>
                <div className="w-full md:w-4/12 text-left md:text-right">
                  <Helper name="Hardware" data={entry.data.hardware} />
                </div>
              </div>
              {!!selectedVariant && 
                <div className="flex justify-between gap-6">
                  <div className="sr-only">Choose hardware</div>
                  <div className="w-6/12 md:w-9/12">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left md:text-center">
                      {custom_metal_hardware.map((custom,i) => (
                        <label htmlFor={`metal_hardware_${custom.name}`} key={i} className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                          <input
                            id={`metal_hardware_${custom.name}`}
                            checked={custom.name === selectedMetalHardware}
                            type="radio"
                            value={custom.name}
                            onChange={handleMetalHardwareChange}
                            name="metal_hardware"
                            style={{backgroundColor: `${custom.colour}`}}
                            className={custom.name === selectedMetalHardware ? 'w-5 h-5 text-lg text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 text-lg bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                          />
                          {custom.name}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className="w-6/12 md:w-3/12">
                    <GatsbyImage className="object-fit w-full block" image={selectedMetalHardwareImage.image.gatsbyImageData} alt={selectedMetalHardwareImage.image.alt ?? ''} />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/embellishments_white.png" className="w-28 h-28" alt="Embellishments" />
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex-none md:flex items-center justify-between pb-6">
                <div className="w-full md:w-8/12">
                  <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Add embellishments</h2>
                </div>
                <div className="w-full md:w-4/12 text-left md:text-right">
                  <Helper name="Embellishments" data={entry.data.embellishments} />
                </div>
              </div>
              <div className={selectedEmbellishmentsOption === 'No' ? 'w-full' : 'w-full pb-6'}>
                {!!selectedVariant && 
                  <div className="flex justify-between gap-6">
                    <div className="sr-only">Embellishments?</div>
                    <div className="w-9/12">
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left md:text-center">
                        {data.shopifyProduct.options[1].values.map((name,i) => (
                          <label htmlFor={name} key={i} className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                            <input
                              id={name}
                              defaultChecked={name === selectedEmbellishmentsOption}
                              type="radio"
                              value={name}
                              onChange={handleVariantEmbellishmentsOptionChange}
                              name="embellishments"
                              className={name === selectedEmbellishmentsOption ? 'w-5 h-5 text-sm md:text-base text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 text-sm md:text-base bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                            />
                            {name}
                          </label>
                        ))}
                      </div>
                    </div>
                    <div className="w-3/12">
                      &nbsp;
                    </div>
                  </div>
                }
              </div>

              <div className={selectedEmbellishmentsOption === 'No' ? 'hidden' : 'w-full pt-6 md:pt-0'}>
                {!!selectedVariant && 
                  <div className="flex justify-between gap-6">
                    <div className="sr-only">Choose an embellishment</div>
                    <div className="w-6/12 md:w-9/12">
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left md:text-center">
                        {custom_embellishments.map((custom,i) => (
                          <label htmlFor={custom.name} key={i} className="text-sm md:text-base relative flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                            <input
                              id={custom.name}
                              checked={custom.name === selectedEmbellishmentsColour}
                              type="radio"
                              value={custom.name}
                              onChange={handleEmbellishmentsChange}
                              name="custom_embellishments"
                              style={{backgroundColor: `${custom.colour}`}}
                              className={custom.name === selectedEmbellishmentsColour ? 'relative w-5 h-5 text-sm md:text-base text-transparent border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'relative w-5 h-5 text-sm md:text-base text-transparent bg-transparent text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                            />
                            {custom.name}
                          </label>
                        ))}
                      </div>
                    </div>
                    <div className="w-6/12 md:w-3/12">
                      <div className="flex justify-center items-center">
                        <GatsbyImage className="object-fit block" image={selectedEmbellishmentsImage.image.gatsbyImageData} alt={selectedEmbellishmentsImage.image.alt ?? ''} />  
                      </div>
                     </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/cart_duo_white.png" className="w-24 h-24" alt="" />
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">

              <div className="flex items-center justify-between pb-6">
                <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Add to cart</h2>
              </div>

              <div className="max-w-none prose font-poppins prose-p:text-diamond-80 prose-h2:font-semibold prose-h2:text-diamond prose-h2:mb-3 prose-p:font-medium leading-snug mb-12">
                <h2>Please check your selection</h2>
                <PrismicRichText linkResolver={linkResolver} field={entry.data.info.richText} />
              </div>

              {selectedVariant && (
                <div className="w-full pb-12">
                  <div className="font-poppins max-w-none font-semibold text-diamond text-4xl xl:text-6xl">
                    {selectedVariant.compareAtPriceV2 && selectedVariant.priceV2.amount < selectedVariant.compareAtPriceV2.amount ? (
                      <div className="flex flex-col lg:flex-row gap-0 lg:gap-6">
                        <div className="line-through">{currency.format(selectedVariant.compareAtPriceV2.amount)} NZD</div>
                        <div className="text-orange">{currency.format(selectedVariant.priceV2.amount)} NZD</div>
                      </div>
                    ) : (
                      <>
                        {currency.format(selectedVariant.priceV2.amount)} NZD
                      </>
                    )}
                  </div>
                </div>
              )}

              {!!selectedVariant && (
              <div className="w-full">
                <ProductQuantity formValues={formValues} variantId={selectedVariant.id} customSelections={customSelections} />
              </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ProductSafety data={entry.data} />
      <Footer />
    </>
  )
}

export const query = graphql`
query {
  prismicSizeGuideCatCollars {
    data {
      heading
      text {
        richText
      }
      table_1 {
        col_1
        col_2
      }
      image {
        alt
        gatsbyImageData
      }
    }
  }
  prismicCatsCollarsBreakawaySafety {
    data {
      out_of_stock {
        size
        component
        name
      }
      page_title
      meta_description
      heading
      description {
        richText
      }
      images {
        image {
          alt
          gatsbyImageData
        }
      }
      safety_and_care_image {
        alt
        gatsbyImageData
      }
      safety_and_care {
        richText
      }
      info {
        richText
      }
      strapping {
        richText
      }
      hardware {
        richText
      }
      embellishments {
        richText
      }
      custom_strap_colour {
        name
        colour
        image {
          alt
          gatsbyImageData
        }
      }
      custom_strap_keeper_colour {
        name
        colour
        image {
          alt
          gatsbyImageData
        }
      }
      custom_breakaway_buckle_colour {
        name
        colour
        image {
          alt
          gatsbyImageData
        }
      }
      custom_metal_hardware {
        name
        colour
        image {
          alt
          gatsbyImageData
        }
      }
      custom_embellishments {
        name
        colour
        image {
          alt
          gatsbyImageData
        }
      }
    }
  }
  shopifyProduct(handle: {eq: "cats-collars-breakaway-safety"}) {
    id
    priceRangeV2 {
      maxVariantPrice {
        amount
      }
    }
    shopifyId
    storefrontId
    status
    variants {
      id
      price
      shopifyId
      storefrontId
      title
      availableForSale
    }
    options {
      shopifyId
      name
      values
    }
  }
}
`

export default CatsCollarsBreakawaySafety

import * as React from "react"
import { graphql } from "gatsby"
import { useState, useContext, useEffect } from 'react'
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { linkResolver } from '../../../../utils/link_resolver'
import Seo from '../../../../components/seo'
import Footer from '../../../../components/footer'
import Header from '../../../../components/header'
import ProductQuantity from '../../../../components/product_quantity';
import ProductSafety from "../../../../components/product_safety"
import ProductHeader from "../../../../components/product_header"
import ProductCustomHeader from "../../../../components/product_custom_header"
import CartContext from '../../../../components/cart_context';
import Breadcrumb from "../../../../components/breadcrumb"

const DogsSafetyLightsOrbilocDualDog = ({data}) => {
  
  const entry = data.prismicDogsSafetyLightsOrbilocDualDog
  //console.log('entry: ', entry);

  const {getProductById} = useContext(CartContext);
  const [product, setProduct] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [variantId, setVariantId] = useState(null);
  const [selectedSize, setSelectedSize] = useState(data.shopifyProduct.options[0].values[0])
  const [selectedColour, setSelectedColour] = useState(entry.data.custom_colour[0].name)
  const [selectedColourImage, setSelectedColourImage] = useState(entry.data.custom_colour[0])
  const [formValues] = useState({
    formErrors: '',
    formIsValid: true,
  });
  const [customSelections, setCustomSelections] = useState([
    {
      key: "Colour",
      value: selectedColour
    },
  ])

  useEffect(() => {
    getProductById(data.shopifyProduct.shopifyId).then(result => {
      //console.log('selectedVariant: ', selectedVariant);
      setProduct(result);
      if (result) {
        setSelectedVariant(
          result.variants.find(({ id }) => id === variantId) || result.variants[0]
        );
      }
    });
  }, [
    getProductById,
    setProduct,
    data.shopifyProduct.shopifyId,
    variantId,
    selectedSize,
    selectedColour,
    selectedColourImage,
    customSelections
  ]);

  const handleColourChange = e => {
    setSelectedSize(e.target.value);
    let selectedVariantChange = `${e.target.value}`

    let newVariant = data.shopifyProduct?.variants.find(
      variant => variant.title === selectedVariantChange
    )
    setVariantId(newVariant.shopifyId);

    setSelectedColour(e.target.value);
    const newCustomSelections = customSelections.map(customSelection => {
      if (customSelection.key === 'Colour') {
        return {...customSelection, value: e.target.value};
      }
      return customSelection;
    });
    setCustomSelections(newCustomSelections)
    const newSelectedColourImage = entry.data.custom_colour.find(image => (
      image.name === e.target.value
    ))
    setSelectedColourImage(newSelectedColourImage)
  };

  const currency = new Intl.NumberFormat('en-NZ', {
    style: 'currency',
    currency: 'NZD',
    minimumFractionDigits: 2
  })

  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <Header />
      <Breadcrumb 
        category="Dogs" 
        category_url="dogs"
        subcategory="Safety Lights" 
        subcategory_url="safety-lights"
        product={entry.data.heading} 
      />
      <ProductHeader data={entry.data} />
      <ProductCustomHeader props='Choose Your Safety Light' />

      <div className="w-full pb-16">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/ethos_white.png" className="w-28 h-28" alt="Colour" />  
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex items-center justify-between pb-6">
                <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Choose colour</h2>
              </div>
              <div className="max-w-none prose font-poppins prose-p:text-diamond-80 prose-h2:font-semibold prose-h2:text-diamond prose-h2:mb-3 prose-p:font-medium prose-li:font-medium leading-snug mb-12">
                <PrismicRichText linkResolver={linkResolver} field={entry.data.copy.richText} />
              </div>
              <div className="flex justify-between gap-6">
                <div className="sr-only">Choose colour</div>
                <div className="w-full">
                  <div className="grid grid-cols-1 gap-3">
                    {entry.data.custom_colour.map((custom,i) => (
                      <label style={custom.name === selectedColour ? {borderColor: custom.colour} : null } htmlFor={custom.name} key={i} className={custom.name === selectedColour ? 'rounded-xl border-2 border-diamond cursor-pointer p-3' : 'p-3 border-2 border-transparent cursor-pointer'}>
                        <div className="flex-none md:flex justify-between items-center gap-3 text-left">
                          <div className="w-full md:w-3/12">
                            <GatsbyImage className="object-contain w-full h-full block" image={custom.image.gatsbyImageData} alt={custom.image.alt ?? ''} />
                          </div>
                          <input
                            id={custom.name}
                            checked={custom.name === selectedColour}
                            type="radio"
                            value={custom.name}
                            onChange={handleColourChange}
                            name="colour"
                            className="hidden"
                          />
                          <div className="w-full md:w-9/12">
                            <div className="pb-3 font-poppins font-semibold text-xl">
                              <h2 style={{color: `${custom.colour}`}}>{custom.name}</h2>
                            </div>
                            <div className="font-poppins font-medium text-diamond-80">
                              {custom.text}
                            </div>
                          </div>
                        </div>
                      </label>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/cart_duo_white.png" className="w-24 h-24" alt="" />
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex items-center justify-between pb-6">
                <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Add to cart</h2>
              </div>
              <div className="max-w-none prose font-poppins prose-p:text-diamond-80 prose-h2:font-semibold prose-h2:text-diamond prose-h2:mb-3 prose-p:font-medium leading-snug mb-12">
                <h2>Please check your selection</h2>
                <PrismicRichText linkResolver={linkResolver} field={entry.data.info.richText} />
              </div>
              
              {selectedVariant && (
                <div className="w-full pb-12">
                  <div className="font-poppins max-w-none font-semibold text-diamond text-4xl xl:text-6xl">
                    {selectedVariant.compareAtPriceV2 && selectedVariant.priceV2.amount < selectedVariant.compareAtPriceV2.amount ? (
                      <div className="flex flex-col lg:flex-row gap-0 lg:gap-6">
                        <div className="line-through">{currency.format(selectedVariant.compareAtPriceV2.amount)} NZD</div>
                        <div className="text-orange">{currency.format(selectedVariant.priceV2.amount)} NZD</div>
                      </div>
                    ) : (
                      <>
                        {currency.format(selectedVariant.priceV2.amount)} NZD
                      </>
                    )}
                  </div>
                </div>
              )}

              {!!selectedVariant && !!selectedVariant?.available === true && (
                <div className="w-full">
                  <ProductQuantity formValues={formValues} variantId={selectedVariant.id} customSelections={customSelections} />
                </div>
              )}

              {!!selectedVariant && !!selectedVariant?.available === false && (
                <div className="w-full">
                  <div className="bg-orange text-white px-6 py-3 font-medium text-lg font-poppins flex items-center justify-center">
                    Sold out
                  </div>
                </div>
              )}
              
            </div>
          </div>
        </div>
      </div>

      <ProductSafety data={entry.data} />
      <Footer />
    </>
  )
}

export const query = graphql`
query {
  prismicDogsSafetyLightsOrbilocDualDog {
    data {
      page_title
      meta_description
      heading
      description {
        richText
      }
      images {
        image {
          alt
          gatsbyImageData
        }
      }
      info {
        richText
      }
      safety_and_care_image {
        alt
        gatsbyImageData
      }
      safety_and_care {
        richText
      }
      copy {
        richText
      }
      custom_colour {
        image {
          alt
          gatsbyImageData
        }
        name
        colour
        text
        tag
      }
    }
  }
  shopifyProduct(handle: {eq: "dogs-safety-lights-orbiloc-dual-dog"}) {
    id
    priceRangeV2 {
      maxVariantPrice {
        amount
      }
    }
    shopifyId
    storefrontId
    status
    variants {
      id
      price
      shopifyId
      storefrontId
      title
      availableForSale
      inventoryQuantity
    }
    options {
      shopifyId
      name
      values
    }
  }
}
`

export default DogsSafetyLightsOrbilocDualDog

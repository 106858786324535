/**
 * Dogs Collars Elegant Component
 * 
 * A complex product customization page that integrates:
 * - Prismic CMS for content management
 * - Shopify for product data and cart functionality
 * - Gatsby for static site generation
 * 
 * Features:
 * - Size selection
 * - Strap color customization
 * - Hardware color selection
 * - Embellishments
 * - Custom neck measurements
 * - Stock level checking
 * - Cart integration
 */

// Core functionality imports
import * as React from "react"
import { graphql } from "gatsby"
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { linkResolver } from '../../../../utils/link_resolver'

// Component imports
import Seo from '../../../../components/seo'
import Footer from '../../../../components/footer'
import Header from '../../../../components/header'
import Helper from '../../../../components/helper'
import HelperSizeDogCollars from '../../../../components/helper_size_dog_collars'
import CartContext from '../../../../components/cart_context'
import ProductQuantity from '../../../../components/product_quantity'
import { useState, useContext, useEffect } from 'react'
import ProductSafety from "../../../../components/product_safety"
import ProductHeader from "../../../../components/product_header"
import ProductCustomHeader from "../../../../components/product_custom_header"
import Breadcrumb from "../../../../components/breadcrumb"

// Form handling imports
import Validate from '../../../../components/validation.js'
import FieldText from '../../../../components/field_text.js'
import FieldErrorMessage from '../../../../components/field_error_message'

const DogsCollarsElegant = ({data}) => {
  
  // Initialize main data sources
  const entry = data.prismicDogsCollarsElegant
  const {getProductById} = useContext(CartContext)

  // Get initial variant based on first size and hardware color
  const initialSize = data.shopifyProduct.options[0].values[0]
  const initialHardwareColors = getStockLevels(entry.data.custom_hardware_colour, 'custom_hardware_colour', initialSize)
  const initialHardwareColor = initialHardwareColors[0]?.name
  
  /**
   * Find initial Shopify variant based on size/hardware combination
   * This sets up the initial product state before any user interaction
   */
  const initialVariantTitle = `${initialSize} / ${initialHardwareColor}`
  const initialVariant = data.shopifyProduct?.variants.find(
    variant => variant.title === initialVariantTitle
  )

  /**
   * Product State Management
   * Tracks the core product data and selected variants
   */
  const [product, setProduct] = useState(null)
  const [selectedVariant, setSelectedVariant] = useState(null)
  const [variantId, setVariantId] = useState(initialVariant?.shopifyId)
  const [selectedSize, setSelectedSize] = useState(initialSize)
  
  /**
   * Custom Options State Management
   * Each customization option maintains three pieces of state:
   * 1. Available options (filtered by stock)
   * 2. Selected option
   * 3. Selected option's image
   */
  let custom_strap_colour = getStockLevels(entry.data.custom_strap_colour, 'custom_strap_colour', selectedSize)
  const [selectedStrapColour, setSelectedStrapColour] = useState(custom_strap_colour[0]?.name)
  const [selectedStrapColourImage, setSelectedStrapColourImage] = useState(custom_strap_colour[0])
  
  let custom_hardware_colour = getStockLevels(entry.data.custom_hardware_colour, 'custom_hardware_colour', selectedSize)
  const [selectedHardwareColour, setSelectedHardwareColour] = useState(initialHardwareColor)
  const [selectedHardwareColourImage, setSelectedHardwareColourImage] = useState(initialHardwareColors[0])

  let custom_embellishments = getStockLevels(entry.data.custom_embellishments, 'custom_embellishments', selectedSize)
  const [selectedEmbellishmentsColour, setSelectedEmbellishmentsColour] = useState(custom_embellishments[0]?.name)
  const [selectedEmbellishmentsImage, setSelectedEmbellishmentsImage] = useState(custom_embellishments[0])

  /**
   * Form State Management
   * Handles neck measurement input and validation
   */
  const [formValues, setFormValues] = useState({
    formErrors: '',
    formIsValid: true,
    formControls: {
      neck_measurement: {
        type: 'text',
        value: '',
        placeholder: '',
        valid: true,
        touched: false,
        validationRules: {
            maxLength: '12'
        },
        errorMessages: ''
      },
    }
  })

  /**
   * Custom Selections Tracking
   * Maintains a single source of truth for all selections
   * Used for cart integration and order processing
   */
  const [customSelections, setCustomSelections] = useState([
    {
      key: "Strap Colour",
      value: selectedStrapColour
    },
    {
      key: "Hardware Colour",
      value: selectedHardwareColour
    },
    {
      key: "Embellishments",
      value: selectedEmbellishmentsColour
    },
    {
      key: "Neck Measurement",
      value: formValues.formControls.neck_measurement.value
    },
  ])

  /**
   * Product Data Effect
   * Fetches updated product data when variant changes
   * Updates selected variant based on current selections
   */
  useEffect(() => {
    if (variantId) {
      getProductById(data.shopifyProduct.shopifyId).then(result => {
        setProduct(result)
        if (result) {
          const variant = result.variants.find(({ id }) => id === variantId)
          setSelectedVariant(variant || result.variants[0])
        }
      })
    }
  }, [
    getProductById,
    data.shopifyProduct.shopifyId,
    variantId
  ])

  /**
   * Stock Level Check Function
   * @param {Array} data - Array of color options
   * @param {String} component - Type of component (strap, hardware, etc.)
   * @param {String} size - Selected size
   * @returns {Array} - Filtered array of in-stock options
   */
  function getStockLevels(data, component, size) {
    return data.filter(colour => {
      return !entry.data.out_of_stock.some(stock => {
        if (stock.size === size && stock.component === component) {
          return stock.name === colour.name
        }
        return false
      })
    })
  }

  /**
   * Custom Selections Update Function
   * @param {String} component - Component being updated
   * @param {String} name - New selection value
   * @param {Array} prevCustomSelections - Previous selections state
   * @returns {Array} - Updated selections array
   */
  function newCustomSelections(component, name, prevCustomSelections) {
    const newCustomSelections = prevCustomSelections.map(customSelection => {
      if (customSelection.key === component) {
        return {...customSelection, value: name}
      }
      return customSelection
    })
    return newCustomSelections
  }

  /**
   * Size Change Handler
   * Updates:
   * - Selected size
   * - Variant ID
   * - Available options for all customizations
   * - Selected options for all customizations
   */
  const handleVariantSizeChange = e => {
    setSelectedSize(e.target.id)
    let selectedVariantChange = `${e.target.id} / ${selectedHardwareColour}`
    let newVariant = data.shopifyProduct?.variants.find(
      variant => variant.title === selectedVariantChange
    )
    setVariantId(newVariant.shopifyId)

    // Reset all customization options based on new size
    custom_strap_colour = getStockLevels(entry.data.custom_strap_colour, 'custom_strap_colour')
    setSelectedStrapColour(custom_strap_colour[0].name)
    setSelectedStrapColourImage(custom_strap_colour[0])
    setCustomSelections(prevCustomSelections => newCustomSelections('Strap Colour', custom_strap_colour[0].name, prevCustomSelections))
 
    custom_hardware_colour = getStockLevels(entry.data.custom_hardware_colour, 'custom_hardware_colour')
    setSelectedHardwareColour(custom_hardware_colour[0].name)
    setSelectedHardwareColourImage(custom_hardware_colour[0])
    setCustomSelections(prevCustomSelections => newCustomSelections('Hardware Colour', custom_hardware_colour[0].name, prevCustomSelections))
  
    custom_embellishments = getStockLevels(entry.data.custom_embellishments, 'custom_embellishments')
    setSelectedEmbellishmentsColour(custom_embellishments[0].name)
    setSelectedEmbellishmentsImage(custom_embellishments[0])
    setCustomSelections(prevCustomSelections => newCustomSelections('Embellishments', custom_embellishments[0].name, prevCustomSelections))
  }

  /**
   * Strap Color Change Handler
   * Updates:
   * - Selected strap color
   * - Custom selections array
   * - Selected strap color image
   */
  const handleStrapColourChange = e => {
    setSelectedStrapColour(e.target.value)
    const newCustomSelections = customSelections.map(customSelection => {
      if (customSelection.key === 'Strap Colour') {
        return {...customSelection, value: e.target.value}
      }
      return customSelection
    })
    setCustomSelections(newCustomSelections)
    const newSelectedStrapColourImage = entry.data.custom_strap_colour.find(image => (
      image.name === e.target.value
    ))
    setSelectedStrapColourImage(newSelectedStrapColourImage)
  }

  /**
   * Hardware Color Change Handler
   * Updates:
   * - Selected hardware color
   * - Variant ID (hardware affects product variant)
   * - Custom selections array
   * - Selected hardware color image
   */
  const handleHardwareColourChange = e => {
    setSelectedHardwareColour(e.target.value)

    let selectedVariantChange = `${selectedSize} / ${e.target.value}`
    let newVariant = data.shopifyProduct?.variants.find(
      variant => variant.title === selectedVariantChange
    )
    setVariantId(newVariant.shopifyId)

    const newCustomSelections = customSelections.map(customSelection => {
      if (customSelection.key === 'Hardware Colour') {
        return {...customSelection, value: e.target.value}
      }
      return customSelection
    })
    setCustomSelections(newCustomSelections)
    const newSelectedHardwareColourImage = entry.data.custom_hardware_colour.find(image => (
      image.name === e.target.value
    ))
    setSelectedHardwareColourImage(newSelectedHardwareColourImage)
  }

  /**
   * Embellishments Change Handler
   * Updates:
   * - Selected embellishment
   * - Custom selections array
   * - Selected embellishment image
   */
  const handleEmbellishmentsChange = e => {
    setSelectedEmbellishmentsColour(e.target.id)
    const newCustomSelections = customSelections.map(customSelection => {
      if (customSelection.key === 'Embellishments') {
        return {...customSelection, value: e.target.id}
      }
      return customSelection
    })
    setCustomSelections(newCustomSelections)

    const newSelectedEmbellishmentsImage = entry.data.custom_embellishments.find(image => (
      image.name === e.target.id
    ))
    setSelectedEmbellishmentsImage(newSelectedEmbellishmentsImage)
  }

  /**
   * Neck Measurement Change Handler
   * Updates:
   * - Form state and validation
   * - Custom selections array
   * Handles input validation and error messages
   */
  const handleNeckMeasurementChange = e => {
    const name = e.target.name
    const value = e.target.value

    // Update form controls
    const updatedControls = {
      ...formValues.formControls
    }
    const updatedFormElement = {
      ...updatedControls[name]
    }
    updatedFormElement.value = value
    updatedFormElement.touched = true

    // Validate input
    let validateFormElement = []
    validateFormElement = Validate(value, updatedFormElement.validationRules)

    updatedFormElement.valid = validateFormElement.isValid
    updatedFormElement.errorMessages = validateFormElement.errorMessages

    updatedControls[name] = updatedFormElement

    // Check overall form validity
    let formIsValid = true
    for (let inputIdentifier in updatedControls) {
      formIsValid = updatedControls[inputIdentifier].valid && formIsValid
    }

    setFormValues({
      ...formValues,
      formControls: updatedControls,
      formIsValid: formIsValid,
    })

    // Update custom selections
    const newCustomSelections = customSelections.map(customSelection => {
      if (name === 'neck_measurement' && customSelection.key === 'Neck Measurement') {
        return {...customSelection, value: value}
      }
      return customSelection
    })
    setCustomSelections(newCustomSelections)
  }

  /**
   * Currency Formatter
   * Formats prices in NZD with appropriate decimals
   */
  const currency = new Intl.NumberFormat('en-NZ', {
    style: 'currency',
    currency: 'NZD',
    minimumFractionDigits: 2
  })

  // Guard clause for missing data
  if (!entry) return null

  /**
   * Component Render
   * Structured in sections:
   * 1. SEO and Header components
   * 2. Size selection
   * 3. Strap color selection
   * 4. Hardware selection
   * 5. Embellishments selection
   * 6. Cart section
   * 7. Product safety info
   */
  return (
    <>
      {/* SEO and Header Components */}
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <Header />
      <Breadcrumb 
        category="Dogs" 
        category_url="dogs"
        subcategory="Collars" 
        subcategory_url="collars"
        product={entry.data.heading} 
      />
      <ProductHeader data={entry.data} />
      <ProductCustomHeader props="Customise Yours" />

      <div className="w-full pb-16">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/size_duo_white.png" className="w-24 h-24" alt="Strap" /> 
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex-none md:flex items-center justify-between pb-6">
                <div className="w-full md:w-8/12">
                  <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Choose size</h2>
                </div>
                <div className="w-full md:w-4/12 text-left md:text-right">
                  <HelperSizeDogCollars name="Size guide" data={data} />
                </div>
              </div>
              {!!selectedVariant && 
                <div>
                  <div className="sr-only">Choose a size</div>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left ">
                    {data.shopifyProduct.options[0].values.map((name,i) => (
                      <label htmlFor={name} key={i} className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                        <input 
                          id={name}
                          defaultChecked={name === selectedSize}
                          type="radio"
                          value={name}
                          onChange={handleVariantSizeChange}
                          name="size"
                          className={name === selectedSize ? 'w-5 h-5 font-poppins text-lg bg-orange text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 text-lg bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                        />
                        {name}
                      </label>
                    ))}
                  </div>
                  <div className="mt-12 mb-3">
                    <h2 className="font-poppins text-2xl font-semibold text-diamond pb-3 block">
                      Add your measurement <small className="text-sm">(optional)</small>
                    </h2>
                    <p className="font-poppins text-sm md:text-base font-medium text-diamond-80 leading-normal">
                      To ensure a perfectly fitting collar, please provide your dog's neck measurement below (in cm).
                    </p>
                  </div>
                  <div className="flex-none md:flex justify-between gap-6">
                    <div className="sr-only">Provide measurement</div>
                    <div className="w-full md:w-9/12">
                      <div className="flex-none md:flex gap-6">
                        <div className="w-full md:w-6/12">
                          <label htmlFor="neck_measurement" className="sr-only">Add your measurement</label>
                          <FieldText
                            id="neck_measurement"
                            name="neck_measurement"
                            type={formValues.formControls.neck_measurement.type}
                            placeholder={formValues.formControls.neck_measurement.placeholder}
                            value={formValues.formControls.neck_measurement.value}
                            onChange={handleNeckMeasurementChange}
                            className={formValues.formControls.neck_measurement.touched && !formValues.formControls.neck_measurement.valid ? 'w-full px-2 py-1 border-2 border-orange mb-1 focus:border-orange focus:outline-none focus:ring-0' : 'font-poppins font-medium text-diamond-80 w-full px-2 py-1 border-2 border-diamond-50 mb-4 focus:border-diamond-80 focus:outline-none focus:ring-0'}
                          />
                          <FieldErrorMessage
                            className={formValues.formControls.neck_measurement.touched && !formValues.formControls.neck_measurement.valid ? 'block text-xs text-orange mb-4' : 'hidden'}
                            errorMessages={formValues.formControls.neck_measurement.errorMessages}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="w-full md:w-3/12">
                      &nbsp;
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/strap_white.png" className="w-24 h-24" alt="" />
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex-none md:flex items-center justify-between pb-6">
                <div className="w-full md:w-8/12">
                  <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Choose strap colour</h2>
                </div>
                <div className="w-full md:w-4/12 text-left md:text-right">
                  <Helper name="Strapping" data={entry.data.strapping} />
                </div>
              </div>
              {!!selectedVariant && 
                <div className="flex justify-between gap-6">
                  <div className="sr-only">Choose strap colour</div>
                  <div className="w-6/12 md:w-9/12">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left ">
                      {custom_strap_colour.map((custom,i) => (
                        <label htmlFor={`s_${custom.name}`} key={i} className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                          <input
                            id={`s_${custom.name}`}
                            checked={custom.name === selectedStrapColour}
                            type="radio"
                            value={custom.name}
                            onChange={handleStrapColourChange}
                            name="strap_colour"
                            style={{backgroundColor: `${custom.colour}`}}
                            className={custom.name === selectedStrapColour ? 'w-5 h-5 text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                          />
                          {custom.name}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className="w-6/12 md:w-3/12">
                    <GatsbyImage className="object-fit w-full h-24 block" image={selectedStrapColourImage.image.gatsbyImageData} alt={selectedStrapColourImage.image.alt ?? ''} />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-12">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/hardware_white.png" className="w-24 h-24" alt="" />
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex-none md:flex items-center justify-between pb-6">
                <div className="w-full md:w-8/12">
                  <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Choose hardware</h2>
                </div>
                <div className="w-full md:w-4/12 text-left md:text-right">
                  <Helper name="Hardware" data={entry.data.hardware} />
                </div>
              </div>
              {!!selectedVariant && 
                <div className="flex justify-between gap-6">
                  <div className="sr-only">Choose hardware</div>
                  <div className="w-6/12 md:w-9/12">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left ">
                      {custom_hardware_colour.map((custom,i) => (
                        <label htmlFor={custom.name} key={i} className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                          <input
                            id={custom.name}
                            checked={custom.name === selectedHardwareColour}
                            type="radio"
                            value={custom.name}
                            onChange={handleHardwareColourChange}
                            name="hardware_colour"
                            style={{backgroundColor: `${custom.colour}`}}
                            className={custom.name === selectedHardwareColour ? 'w-5 h-5 text-lg text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 text-lg bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                          />
                          {custom.name}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className="w-6/12 md:w-3/12">
                    <GatsbyImage className="object-fit w-full block" image={selectedHardwareColourImage.image.gatsbyImageData} alt={selectedHardwareColourImage.image.alt ?? ''} />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/embellishments_white.png" className="w-28 h-28" alt="Embellishments" />
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex-none md:flex items-center justify-between pb-6">
                <div className="w-full md:w-8/12">
                  <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Choose embellishments</h2>
                </div>
                <div className="w-full md:w-4/12 text-left md:text-right">
                  <Helper name="Embellishments" data={entry.data.embellishments} />
                </div>
              </div>
              <div className="w-full">
                {!!selectedVariant && 
                  <div className="flex justify-between gap-6">
                    <div className="sr-only">Choose an embellishment</div>
                    <div className="w-6/12 md:w-9/12">
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left ">
                        {custom_embellishments.map((custom,i) => (
                          <label htmlFor={custom.name} key={i} className="text-sm md:text-base relative flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                            <input
                              id={custom.name}
                              checked={custom.name === selectedEmbellishmentsColour}
                              type="radio"
                              value={custom.name}
                              onChange={handleEmbellishmentsChange}
                              name="custom_embellishments"
                              style={{backgroundColor: `${custom.colour}`}}
                              className={custom.name === selectedEmbellishmentsColour ? 'relative w-5 h-5 text-lg text-transparent border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'relative w-5 h-5 text-lg text-transparent bg-transparent text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                            />
                            {custom.name}
                          </label>
                        ))}
                      </div>
                    </div>
                    <div className="w-6/12 md:w-3/12">
                      <div className="flex justify-center items-center">
                        <GatsbyImage className="object-fit block" image={selectedEmbellishmentsImage.image.gatsbyImageData} alt={selectedEmbellishmentsImage.image.alt ?? ''} />  
                      </div>
                     </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/cart_duo_white.png" className="w-24 h-24" alt="" />
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex items-center justify-between pb-6">
                <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Add to cart</h2>
              </div>
              <div className="max-w-none prose font-poppins prose-p:text-diamond-80 prose-h2:font-semibold prose-h2:text-diamond prose-h2:mb-3 prose-p:font-medium leading-snug mb-12">
                <h2>Please check your selection</h2>
                <PrismicRichText linkResolver={linkResolver} field={entry.data.info.richText} />
              </div>
              {selectedVariant && (
                <div className="w-full pb-12">
                  <div className="font-poppins max-w-none font-semibold text-diamond text-4xl xl:text-6xl">
                    {selectedVariant.compareAtPriceV2 && selectedVariant.priceV2.amount < selectedVariant.compareAtPriceV2.amount ? (
                      <div className="flex flex-col lg:flex-row gap-0 lg:gap-6">
                        <div className="line-through">{currency.format(selectedVariant.compareAtPriceV2.amount)} NZD</div>
                        <div className="text-orange">{currency.format(selectedVariant.priceV2.amount)} NZD</div>
                      </div>
                    ) : (
                      <>
                        {currency.format(selectedVariant.priceV2.amount)} NZD
                      </>
                    )}
                  </div>
                </div>
              )}
              {!!selectedVariant && (
              <div className="w-full">
                <ProductQuantity formValues={formValues} variantId={selectedVariant.id} customSelections={customSelections} />
              </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ProductSafety data={entry.data} />
      <Footer />
    </>
  )
}

/**
 * GraphQL Query
 * Fetches all required data from:
 * - Prismic CMS: Content, images, and customization options
 * - Shopify: Product variants and pricing
 */
export const query = graphql`
query {
  prismicSizeGuideDogCollars {
    data {
      heading
      text {
        richText
      }
      text1 {
        richText
      }
      table_1 {
        heading1
        text1
      }
      image {
        alt
        gatsbyImageData
      }
      table_2 {
        heading1
        text1
      }
    }
  }
  prismicDogsCollarsElegant {
    data {
      out_of_stock {
        size
        component
        name
      }
      page_title
      meta_description
      tag
      heading
      description {
        richText
      }
      images {
        image {
          alt
          gatsbyImageData
        }
      }
      safety_and_care_image {
        alt
        gatsbyImageData
      }
      safety_and_care {
        richText
      }
      info {
        richText
      }
      strapping {
        richText
      }
      hardware {
        richText
      }
      embellishments {
        richText
      }
      custom_strap_colour {
        name
        colour
        image {
          alt
          gatsbyImageData
        }
      }
      custom_hardware_colour {
        name
        colour
        image {
          alt
          gatsbyImageData
        }
      }
      custom_embellishments {
        name
        colour
        image {
          alt
          gatsbyImageData
        }
      }
    }
  }
  shopifyProduct(handle: {eq: "dogs-collars-elegant"}) {
    id
    priceRangeV2 {
      maxVariantPrice {
        amount
      }
    }
    shopifyId
    storefrontId
    status
    variants {
      id
      price
      shopifyId
      storefrontId
      title
      availableForSale
    }
    options {
      shopifyId
      name
      values
    }
  }
}
`

export default DogsCollarsElegant

import * as React from "react"
import { graphql, Link } from "gatsby"
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { linkResolver } from '../../../utils/link_resolver'
import Seo from '../../../components/seo'
import Footer from '../../../components/footer'
import Header from '../../../components/header'
import HelperSizeSign from '../../../components/helper_size_sign'
import HelperMaterialSign from '../../../components/helper_material_sign'
import HelperAttachmentSign from '../../../components/helper_attachment_sign'
import CartContext from '../../../components/cart_context';
import ProductQuantity from '../../../components/product_quantity';
import { useState, useContext, useEffect } from 'react'
import ProductSafety from "../../../components/product_safety"
import ProductHeader from "../../../components/product_header"
import ProductCustomHeader from "../../../components/product_custom_header"
import Breadcrumb from "../../../components/breadcrumb"
import Validate from '../../../components/validation.js';
import FieldText from '../../../components/field_text.js';
import FieldErrorMessage from '../../../components/field_error_message';

const SignsNameSign = ({data}) => {  
  const entry = data.prismicSignsNameSign

  // needs error callbacks
  const {getProductById} = useContext(CartContext);
  
  const [product, setProduct] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [variantId, setVariantId] = useState(null);
  const [selectedShapeSize, setSelectedShapeSize] = useState(data.shopifyProduct.options[0].values[0])

  let custom_background_colour = getStockLevels(entry.data.custom_background_colour, 'custom_background_colour');
  const [selectedBackgroundColour, setSelectedBackgroundColour] = useState(custom_background_colour[0].name)
  const [selectedBackgroundColourImage, setSelectedBackgroundColourImage] = useState(custom_background_colour[0])
  
  let custom_text_colour = getStockLevels(entry.data.custom_text_colour, 'custom_text_colour');
  const [selectedTextColour, setSelectedTextColour] = useState(custom_text_colour[0].name)
  const [selectedTextColourImage, setSelectedTextColourImage] = useState(custom_text_colour[0])
  
  let custom_attachment_method = getStockLevels(entry.data.custom_attachment_method, 'custom_attachment_method');
  const [selectedAttachmentMethod, setSelectedAttachmentMethod] = useState(custom_attachment_method[0].name)
  const [selectedAttachmentMethodImage, setSelectedAttachmentMethodImage] = useState(custom_attachment_method[0])

  let custom_name_plate_font = entry.data.custom_name_plate_font;
  const [selectedNamePlateFont, setSelectedNamePlateFont] = useState(custom_name_plate_font[0].name)

  const [formValues, setFormValues] = useState({
    formErrors: '',
    formIsValid: false,
    formControls: {
      sign_text: {
        type: 'text',
        value: '',
        placeholder: '',
        valid: false,
        touched: false,
        validationRules: {
            isRequired:  true,
            maxLength: '12'
        },
        errorMessages: ''
      },
    }
  });

  const [customSelections, setCustomSelections] = useState([
    {
      key: "Shape & Size",
      value: selectedShapeSize
    },
    {
      key: "Background Colour",
      value: selectedBackgroundColour
    },
    {
      key: "Text Colour",
      value: selectedTextColour
    },
    {
      key: "Attachment Method",
      value: selectedAttachmentMethod
    },
    {
      key: "Sign Text",
      value: ''
    },
    {
      key: "Sign Font",
      value: selectedNamePlateFont
    }
  ])
  
  // data.shopifyProduct.storefrontId
  // data.shopifyProduct.shopifyId

  useEffect(() => {
    //console.log('data.shopifyProduct.shopifyId: ', data.shopifyProduct);
    getProductById(data.shopifyProduct.shopifyId).then(result => {
      setProduct(result);
      if (result) {
        setSelectedVariant(
          result.variants.find(({ id }) => id === variantId) || result.variants[0]
        );
      }
    });
  }, [
    getProductById,
    setProduct,
    data.shopifyProduct.shopifyId,
    variantId,
    selectedBackgroundColour,
    selectedBackgroundColourImage,
    selectedTextColour,
    selectedTextColourImage,
    selectedAttachmentMethod,
    selectedAttachmentMethodImage,
    selectedNamePlateFont,
    customSelections,
  ]);

  function getStockLevels(data, component) {
    const newStock = data.filter(colour => {
      return !entry.data.out_of_stock.some(stock => {
        if (stock.size === selectedShapeSize && stock.component === component) {
          return stock.name === colour.name;
        }
        return false;
      });
    });
    return newStock;
  }

  function newCustomSelections(component, name, prevCustomSelections) {
    const newCustomSelections = prevCustomSelections.map(customSelection => {
      if (customSelection.key === component) {
        return {...customSelection, value: name};
      }
      return customSelection;
    });
    return newCustomSelections;
  }
  
  const handleVariantChange = e => {
    setSelectedShapeSize(e.target.id);
    let selectedVariantChange = `${e.target.id}`;

    let newVariant = data.shopifyProduct?.variants.find(
      variant => variant.title === selectedVariantChange
    );
    setVariantId(newVariant.shopifyId);

    const newCustomSelections = customSelections.map(customSelection => {
      if (customSelection.key === 'Shape & Size') {
        return {...customSelection, value: e.target.value};
      }
      return customSelection;
    });
    setCustomSelections(newCustomSelections)

    /* set_stock
    custom_colour_combination = getStockLevels(entry.data.custom_colour_combination, 'custom_colour_combination');
    setSelectedColourCombination(custom_colour_combination[0].name)
    setSelectedColourCombinationImage(custom_colour_combination[0])
    setCustomSelections(prevCustomSelections => newCustomSelections('Colour Combination', custom_colour_combination[0].name, prevCustomSelections));

    custom_attachment_method = getStockLevels(entry.data.custom_attachment_method, 'custom_attachment_method');
    setSelectedAttachmentMethod(custom_attachment_method[0].name)
    setSelectedAttachmentMethodImage(custom_attachment_method[0])
    setCustomSelections(prevCustomSelections => newCustomSelections('Attachment Method', custom_attachment_method[0].name, prevCustomSelections));
    */
  };

  const handleBackgroundColourChange = e => {
    setSelectedBackgroundColour(e.target.value);
    const newCustomSelections = customSelections.map(customSelection => {
      if (customSelection.key === 'Background Colour') {
        return {...customSelection, value: e.target.value};
      }
      return customSelection;
    });
    setCustomSelections(newCustomSelections)
    const newSelectedBackgroundColourImage = entry.data.custom_background_colour.find(image => (
      image.name === e.target.value
    ))
    setSelectedBackgroundColourImage(newSelectedBackgroundColourImage)
  };

  const handleTextColourChange = e => {
    setSelectedTextColour(e.target.value);
    const newCustomSelections = customSelections.map(customSelection => {
      if (customSelection.key === 'Text Colour') {
        return {...customSelection, value: e.target.value};
      }
      return customSelection;
    });
    setCustomSelections(newCustomSelections)
    const newSelectedTextColourImage = entry.data.custom_text_colour.find(image => (
      image.name === e.target.value
    ))
    setSelectedTextColourImage(newSelectedTextColourImage)
  };

  const handleAttachmentMethodChange = e => {
    setSelectedAttachmentMethod(e.target.value);
    const newCustomSelections = customSelections.map(customSelection => {
      if (customSelection.key === 'Attachment Method') {
        return {...customSelection, value: e.target.value};
      }
      return customSelection;
    });
    setCustomSelections(newCustomSelections)
    const newSelectedAttachmentMethodImage = entry.data.custom_attachment_method.find(image => (
      image.name === e.target.value
    ))
    setSelectedAttachmentMethodImage(newSelectedAttachmentMethodImage)
  };

  const handleNamePlateFontChange = e => {
    setSelectedNamePlateFont(e.target.value);
    const newCustomSelections = customSelections.map(customSelection => {
      if (customSelection.key === 'Sign Font') {
        return {...customSelection, value: e.target.value};
      }
      return customSelection;
    });
    setCustomSelections(newCustomSelections)
  };

  const changeFormHandler = e => {
    const name = e.target.name;
    const value = e.target.value;

    const updatedControls = {
      ...formValues.formControls
    };
    const updatedFormElement = {
      ...updatedControls[name]
    };
    updatedFormElement.value = value;
    updatedFormElement.touched = true;

    let validateFormElement = [];
    validateFormElement = Validate(value, updatedFormElement.validationRules);

    updatedFormElement.valid = validateFormElement.isValid;
    updatedFormElement.errorMessages = validateFormElement.errorMessages;

    updatedControls[name] = updatedFormElement;

    let formIsValid = true;
    for (let inputIdentifier in updatedControls) {
      formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
    }

    setFormValues({
      ...formValues,
      formControls: updatedControls,
      formIsValid: formIsValid,
    });

    const newCustomSelections = customSelections.map(customSelection => {
      if (name === 'sign_text' && customSelection.key === 'Sign Text') {
        return {...customSelection, value: value};
      }
      return customSelection;
    });
    setCustomSelections(newCustomSelections)
  }

  const currency = new Intl.NumberFormat('en-NZ', {
    style: 'currency',
    currency: 'NZD',
    minimumFractionDigits: 2
  })

  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <Header />
      <Breadcrumb 
        category="Signs" 
        category_url="signs"
        subcategory="" 
        subcategory_url=""
        product={entry.data.heading} 
      />
      <ProductHeader data={entry.data} />
      <ProductCustomHeader props="Customise Yours" />

      <div className="w-full pb-16">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/size_duo_white.png" className="w-24 h-24" alt="" />
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex-none md:flex items-center justify-between pb-6">
                <div className="w-full md:w-8/12">
                  <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Choose shape and size</h2>
                </div>
                <div className="w-full md:w-4/12 text-left md:text-right">
                  <HelperSizeSign name="Size guide" data={entry.data} />
                </div>
              </div>
              {!!selectedVariant && 
                <div>
                  <div className="sr-only">Choose shape and size</div>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left ">
                    {data.shopifyProduct.options[0].values.map((name,i) => (
                      <label htmlFor={name} key={i} className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                        <input 
                          id={name}
                          defaultChecked={name === selectedShapeSize}
                          type="radio"
                          value={name}
                          onChange={handleVariantChange}
                          name="size"
                          className={name === selectedShapeSize ? 'w-5 h-5 font-poppins text-lg bg-orange text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 text-lg bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                        />
                        {name}
                      </label>
                    ))}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/ethos_white.png" className="w-28 h-28" alt="Background Colour" />
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex-none md:flex items-center justify-between pb-6">
                <div className="w-full md:w-8/12">
                  <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Choose background colour</h2>
                </div>
                <div className="w-full md:w-4/12 text-left md:text-right">
                  <HelperMaterialSign name="Sign material" data={entry.data} />
                </div>
              </div>
              {!!selectedVariant && 
                <div className="flex justify-between gap-6">
                  <div className="sr-only">Choose background colour</div>
                  <div className="w-6/12 md:w-9/12">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left">
                      {custom_background_colour.map((custom,i) => (
                        <label htmlFor={`background_colour_${custom.name}`} key={i} className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                          <input
                            id={`background_colour_${custom.name}`}
                            checked={custom.name === selectedBackgroundColour}
                            type="radio"
                            value={custom.name}
                            onChange={handleBackgroundColourChange}
                            name="background_colour"
                            style={{backgroundColor: `${custom.colour}`}}
                            className={custom.name === selectedBackgroundColour ? 'w-5 h-5 text-lg text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 text-lg bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                          />
                          {custom.name}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className="w-6/12 md:w-3/12">
                    <GatsbyImage className="object-fit w-full block" image={selectedBackgroundColourImage.image.gatsbyImageData} alt={selectedBackgroundColourImage.image.alt ?? ''} />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/ethos_white.png" className="w-28 h-28" alt="Background Colour" />
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex-none md:flex items-center justify-between pb-6">
                <div className="w-full md:w-8/12">
                  <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Choose text colour</h2>
                </div>
                <div className="w-full md:w-4/12 text-left md:text-right">
                  <HelperMaterialSign name="Sign material" data={entry.data} />
                </div>
              </div>
              {!!selectedVariant && 
                <div className="flex justify-between gap-6">
                  <div className="sr-only">Choose text colour</div>
                  <div className="w-6/12 md:w-9/12">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left">
                      {custom_text_colour.map((custom,i) => (
                        <label htmlFor={`text_colour_${custom.name}`} key={i} className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                          <input
                            id={`text_colour_${custom.name}`}
                            checked={custom.name === selectedTextColour}
                            type="radio"
                            value={custom.name}
                            onChange={handleTextColourChange}
                            name="text_colour"
                            style={{backgroundColor: `${custom.colour}`}}
                            className={custom.name === selectedTextColour ? 'w-5 h-5 text-lg text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 text-lg bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                          />
                          {custom.name}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className="w-6/12 md:w-3/12">
                    <GatsbyImage className="object-fit w-full block" image={selectedTextColourImage.image.gatsbyImageData} alt={selectedTextColourImage.image.alt ?? ''} />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-12">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/ethos_white.png" className="w-28 h-28" alt="Attachments" />
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex-none md:flex items-center justify-between pb-6">
                <div className="w-full md:w-8/12">
                  <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Choose attachment method</h2>
                </div>
                <div className="w-full md:w-4/12 text-left md:text-right">
                  <HelperAttachmentSign name="Size attachments" data={entry.data} />
                </div>
              </div>
              {!!selectedVariant && 
                <div className="flex justify-between gap-6">
                  <div className="sr-only">Choose attachment method</div>
                  <div className="w-6/12 md:w-9/12">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left">
                      {custom_attachment_method.map((custom,i) => (
                        <label htmlFor={`am_${custom.name}`} key={i} className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                          <input
                            id={`am_${custom.name}`}
                            checked={custom.name === selectedAttachmentMethod}
                            type="radio"
                            value={custom.name}
                            onChange={handleAttachmentMethodChange}
                            name="custom_attachment_method"
                            className={custom.name === selectedAttachmentMethod ? 'w-5 h-5 text-lg text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 text-lg bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                          />
                          {custom.name}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className="w-6/12 md:w-3/12">
                    <GatsbyImage className="object-fit w-full block" image={selectedAttachmentMethodImage.image.gatsbyImageData} alt={selectedAttachmentMethodImage.image.alt ?? ''} />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-16 md:pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/personalisation_white.png" className="w-28 h-28" alt="Add text" />  
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex-none md:flex items-center justify-between pb-3">
                <div className="w-full md:w-8/12">
                  <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Add text</h2>
                </div>
                <div className="w-full md:w-4/12 text-left md:text-right">
                  &nbsp;
                </div>
              </div>
              <div className="font-poppins max-w-none pb-12 prose prose-h2:text-orange prose-h2:mb-6 prose-h2:mt-6 prose-h2:font-semibold prose-p:font-medium leading-snug">
                <PrismicRichText linkResolver={linkResolver} field={entry.data.personalisation.richText} />
              </div>

              <div className="w-full pt-6 md:pt-0">
              {!!selectedVariant && 
                <div className="flex-none md:flex justify-between gap-6">
                  <div className="sr-only">Add your text</div>
                  <div className="w-full md:w-9/12">
                    <div>
                      <div className="flex-none md:flex gap-6">
                        <div className="w-full md:w-6/12">
                          <label htmlFor="sign_text" className="font-poppins text-2xl font-semibold text-diamond pb-3 block">Add your text</label>
                          <FieldText
                            id="sign_text"
                            name="sign_text"
                            type={formValues.formControls.sign_text.type}
                            placeholder={formValues.formControls.sign_text.placeholder}
                            value={formValues.formControls.sign_text.value}
                            onChange={changeFormHandler}
                            className={formValues.formControls.sign_text.touched && !formValues.formControls.sign_text.valid ? 'w-full px-2 py-1 border-2 border-orange mb-1 focus:border-orange focus:outline-none focus:ring-0' : 'font-poppins font-medium text-diamond-80 w-full px-2 py-1 border-2 border-diamond-50 mb-4 focus:border-diamond-80 focus:outline-none focus:ring-0'}
                          />
                          <FieldErrorMessage
                            className={formValues.formControls.sign_text.touched && !formValues.formControls.sign_text.valid ? 'block text-xs text-orange mb-4' : 'hidden'}
                            errorMessages={formValues.formControls.sign_text.errorMessages}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="pt-6">
                      <div className="flex items-center justify-between pb-6">
                        <h2 className="font-poppins text-2xl font-semibold text-diamond">Choose your font</h2>
                      </div>
                      <div className="grid grid-cols-3 md:grid-cols-4 gap-3 text-left ">
                        {entry.data.custom_name_plate_font.map((custom, i) => (
                          <label htmlFor={`name_plate_font_${custom.name}`} key={i} className={custom.name === selectedNamePlateFont ? 'flex items-center rounded-xl border-2 border-diamond cursor-pointer' : 'flex items-center border-2 border-transparent cursor-pointer'}>
                            <input
                              id={`name_plate_font_${custom.name}`}
                              defaultChecked={custom.name === selectedNamePlateFont}
                              type="radio"
                              value={custom.name}
                              onChange={handleNamePlateFontChange}
                              name="custom_name_plate_font"
                              className="hidden"
                            />
                            <GatsbyImage className="object-fit w-full block" image={custom.image.gatsbyImageData} alt={custom.image.alt ?? ''} />
                          </label>
                        ))}
                      </div>
                    </div>

                  </div>

                  <div className="w-full md:w-3/12">
                    &nbsp;
                  </div>
                </div>
              }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/cart_duo_white.png" className="w-24 h-24" alt="" />
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">

              <div className="flex items-center justify-between pb-6">
                <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Add to cart</h2>
              </div>

              <div className="max-w-none prose font-poppins prose-p:text-diamond-80 prose-h2:font-semibold prose-h2:text-diamond prose-h2:mb-3 prose-p:font-medium leading-snug mb-12">
                <h2>Please check your selection</h2>
                <PrismicRichText linkResolver={linkResolver} field={entry.data.info.richText} />
              </div>

              {selectedVariant && (
                <div className="w-full pb-12">
                  <div className="font-poppins max-w-none font-semibold text-diamond text-4xl xl:text-6xl">
                    {selectedVariant.compareAtPriceV2 && selectedVariant.priceV2.amount < selectedVariant.compareAtPriceV2.amount ? (
                      <div className="flex flex-col lg:flex-row gap-0 lg:gap-6">
                        <div className="line-through">{currency.format(selectedVariant.compareAtPriceV2.amount)} NZD</div>
                        <div className="text-orange">{currency.format(selectedVariant.priceV2.amount)} NZD</div>
                      </div>
                    ) : (
                      <>
                        {currency.format(selectedVariant.priceV2.amount)} NZD
                      </>
                    )}
                  </div>
                </div>
              )}

              {!!selectedVariant && (
              <div className="w-full">
                <ProductQuantity formValues={formValues} variantId={selectedVariant.id} customSelections={customSelections} />
              </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ProductSafety data={entry.data} />
      <Footer />
    </>
  )
}

export const query = graphql`
query {
  prismicSignsNameSign {
    data {
      out_of_stock {
        size
        component
        name
      }
      page_title
      meta_description
      heading
      tag
      description {
        richText
      }
      images {
        image {
          alt
          gatsbyImageData
        }
      }
      safety_and_care_image {
        alt
        gatsbyImageData
      }
      safety_and_care {
        richText
      }
      info {
        richText
      }
      custom_background_colour {
        name
        colour
        image {
          alt
          gatsbyImageData
        }
      }
      custom_text_colour {
        name
        colour
        image {
          alt
          gatsbyImageData
        }
      }
      custom_attachment_method {
        name
        image {
          alt
          gatsbyImageData
        }
      }
      personalisation {
        richText
      }
      custom_name_plate_font {
        name
        image {
          alt
          gatsbyImageData
        }
      }
      size_guide_text {
        richText
      }
      size_guide_table_1 {
        col_1
        col_2
        col_3
        col_4
      }
      material_text {
        richText
      }
      attachments_text {
        richText
      }
      attachments_table_1 {
        col_1
        col_2
        col_3
        col_4
      }
    }
  }
  shopifyProduct(handle: {eq: "signs-name-sign"}) {
    id
    priceRangeV2 {
      maxVariantPrice {
        amount
      }
    }
    shopifyId
    storefrontId
    status
    variants {
      id
      price
      shopifyId
      storefrontId
      title
      availableForSale
    }
    options {
      shopifyId
      name
      values
    }
  }
}
`

export default SignsNameSign

import * as React from "react"
import { graphql, Link } from "gatsby"
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { linkResolver } from '../../../../utils/link_resolver'
import Seo from '../../../../components/seo'
import Footer from '../../../../components/footer'
import Header from '../../../../components/header'
import Helper from '../../../../components/helper'
import HelperSizeDogLeads from '../../../../components/helper_size_dog_leads'
import CartContext from '../../../../components/cart_context';
import ProductQuantity from '../../../../components/product_quantity';
import { useState, useContext, useEffect } from 'react'
import ProductSafety from "../../../../components/product_safety"
import ProductHeader from "../../../../components/product_header"
import ProductCustomHeader from "../../../../components/product_custom_header"
import Breadcrumb from "../../../../components/breadcrumb"

const DogsLeadsTwoToneCarabiner = ({data}) => {
  
  //console.log('data: ', data)
  const entry = data.prismicDogsLeadsTwoToneCarabiner
  const {getProductById} = useContext(CartContext);
  const [product, setProduct] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [variantId, setVariantId] = useState(null);

  const [selectedSize, setSelectedSize] = useState(data.shopifyProduct.options[0].values[0])
  const [selectedWidth, setSelectedWidth] = useState(entry.data.custom_width[0].name)
  
  let selectedWidthName = entry.data.custom_width[0].name.split(/(?<=^\S+)\s/);
  let lengthOptionsArray = data.shopifyProduct.options[0].values.filter((name) => {       
    let nameOption = name.split(/(?<=^\S+)\s/);
    return (
      nameOption[0] === selectedWidthName[0]
    )
  })
  const [selectedLengthOptions, setSelectedLengthOptions] = useState(lengthOptionsArray)

  let custom_strap_colour_main_lead = getStockLevels(entry.data.custom_strap_colour_main_lead, 'custom_strap_colour_main_lead');
  const [selectedStrapColourMainLead, setSelectedStrapColourMainLead] = useState(custom_strap_colour_main_lead[0].name)
  const [selectedStrapColourMainLeadImage, setSelectedStrapColourMainLeadImage] = useState(custom_strap_colour_main_lead[0])
  
  let custom_strap_colour_accent = getStockLevels(entry.data.custom_strap_colour_accent, 'custom_strap_colour_accent');
  const [selectedStrapColourAccent, setSelectedStrapColourAccent] = useState(custom_strap_colour_accent[0].name)
  const [selectedStrapColourAccentImage, setSelectedStrapColourAccentImage] = useState(custom_strap_colour_accent[0])
  
  let custom_hardware_colour = getStockLevels(entry.data.custom_hardware_colour, 'custom_hardware_colour');
  const [selectedHardwareColour, setSelectedHardwareColour] = useState(custom_hardware_colour[0].name)
  const [selectedHardwareColourImage, setSelectedHardwareColourImage] = useState(custom_hardware_colour[0])

  const [formValues, setFormValues] = useState({
    formErrors: '',
    formIsValid: true,
  });
  const [customSelections, setCustomSelections] = useState([
    {
      key: "Width",
      value: selectedWidth
    },
    {
      key: "Length",
      value: selectedSize
    },
    {
      key: "Strap Colour Main Lead",
      value: selectedStrapColourMainLead
    },
    {
      key: "Strap Colour Accent",
      value: selectedStrapColourAccent
    },
    {
      key: "Hardware Colour",
      value: selectedHardwareColour
    },
  ])

  useEffect(() => {
    getProductById(data.shopifyProduct.shopifyId).then(result => {
      setProduct(result);
      //console.log('result: ', result);
      if (result) {
        setSelectedVariant(
          result.variants.find(({ id }) => id === variantId) || result.variants[0]
        );
      }
    });
  }, [
    getProductById,
    setProduct,
    data.shopifyProduct.shopifyId,
    variantId,
    selectedWidth,
    selectedSize,
    selectedStrapColourMainLead,
    selectedStrapColourMainLeadImage,
    selectedStrapColourAccent,
    selectedStrapColourAccentImage,
    selectedHardwareColour,
    selectedHardwareColourImage,
    customSelections
  ]);

  function getStockLevels(data, component) {
    const newStock = data.filter(colour => {
      return !entry.data.out_of_stock.some(stock => {
        if (stock.size === selectedSize && stock.component === component) {
          return stock.name === colour.name;
        }
        return false;
      });
    });
    return newStock;
  }

  function newCustomSelections(component, name, prevCustomSelections) {
    const newCustomSelections = prevCustomSelections.map(customSelection => {
      if (customSelection.key === component) {
        return {...customSelection, value: name};
      }
      return customSelection;
    });
    return newCustomSelections;
  }

  const handleWidthChange = e => 
  {
    // set width
    setSelectedWidth(e.target.value);
    setCustomSelections(prevCustomSelections => newCustomSelections('Width', e.target.value, prevCustomSelections));

    // get length options
    let selectedWidthOption = e.target.value.split(/(?<=^\S+)\s/);
    let lengthOptionsArray = data.shopifyProduct.options[0].values.filter((name) => {       
      let nameOption = name.split(/(?<=^\S+)\s/);
      return (
        nameOption[0] === selectedWidthOption[0]
      )
    })
    setSelectedLengthOptions(lengthOptionsArray)
    setSelectedSize(lengthOptionsArray[0])
    setCustomSelections(prevCustomSelections => newCustomSelections('Length', lengthOptionsArray[0], prevCustomSelections));

    // get hardware options
    let custom_hardware_colour = getStockLevels(entry.data.custom_hardware_colour, 'custom_hardware_colour');
    setSelectedHardwareColour(custom_hardware_colour[0].name)
    setSelectedHardwareColourImage(custom_hardware_colour[0])
    setCustomSelections(prevCustomSelections => newCustomSelections('Hardware Colour', custom_hardware_colour[0].name, prevCustomSelections));

    // set variant_id
    let selectedVariantChange = `${lengthOptionsArray[0]} / ${custom_hardware_colour[0].name}`
    let newVariant = data.shopifyProduct?.variants.find(
      variant => variant.title === selectedVariantChange
    )
    setVariantId(newVariant.shopifyId);

    // get stock
    let custom_strap_colour_main_lead = getStockLevels(entry.data.custom_strap_colour_main_lead, 'custom_strap_colour_main_lead');
    setSelectedStrapColourMainLead(custom_strap_colour_main_lead[0].name)
    setSelectedStrapColourMainLeadImage(custom_strap_colour_main_lead[0])
    setCustomSelections(prevCustomSelections => newCustomSelections('Strap Colour Main Lead', custom_strap_colour_main_lead[0].name, prevCustomSelections));
  
    let custom_strap_colour_accent = getStockLevels(entry.data.custom_strap_colour_accent, 'custom_strap_colour_accent');
    setSelectedStrapColourAccent(custom_strap_colour_accent[0].name)
    setSelectedStrapColourAccentImage(custom_strap_colour_accent[0])
    setCustomSelections(prevCustomSelections => newCustomSelections('Strap Colour Accent', custom_strap_colour_accent[0].name, prevCustomSelections));
  };

  const handleVariantSizeChange = e => 
  {
    // set length
    setSelectedSize(e.target.value);
    setCustomSelections(prevCustomSelections => newCustomSelections('Length', e.target.value, prevCustomSelections));
  
    // get hardware options
    let custom_hardware_colour = getStockLevels(entry.data.custom_hardware_colour, 'custom_hardware_colour');
    setSelectedHardwareColour(custom_hardware_colour[0].name)
    setSelectedHardwareColourImage(custom_hardware_colour[0])
    setCustomSelections(prevCustomSelections => newCustomSelections('Hardware Colour', custom_hardware_colour[0].name, prevCustomSelections));

    // get stock
    let custom_strap_colour_main_lead = getStockLevels(entry.data.custom_strap_colour_main_lead, 'custom_strap_colour_main_lead');
    setSelectedStrapColourMainLead(custom_strap_colour_main_lead[0].name)
    setSelectedStrapColourMainLeadImage(custom_strap_colour_main_lead[0])
    setCustomSelections(prevCustomSelections => newCustomSelections('Strap Colour Main Lead', custom_strap_colour_main_lead[0].name, prevCustomSelections));
  
    let custom_strap_colour_accent = getStockLevels(entry.data.custom_strap_colour_accent, 'custom_strap_colour_accent');
    setSelectedStrapColourAccent(custom_strap_colour_accent[0].name)
    setSelectedStrapColourAccentImage(custom_strap_colour_accent[0])
    setCustomSelections(prevCustomSelections => newCustomSelections('Strap Colour Accent', custom_strap_colour_accent[0].name, prevCustomSelections));
  
    let selectedVariantChange = `${e.target.value} / ${custom_hardware_colour[0].name}`
    let newVariant = data.shopifyProduct?.variants.find(
      variant => variant.title === selectedVariantChange
    )
    setVariantId(newVariant.shopifyId);
  };

  const handleStrapColourMainLeadChange = e => 
  {
    setSelectedStrapColourMainLead(e.target.value);
    setCustomSelections(prevCustomSelections => newCustomSelections('Strap Colour Main Lead', e.target.value, prevCustomSelections));
    const newImage = entry.data.custom_strap_colour_main_lead.find(image => (
      image.name === e.target.value
    ))
    setSelectedStrapColourMainLeadImage(newImage)
  };

  const handleStrapColourAccentChange = e => 
  {
    setSelectedStrapColourAccent(e.target.value);
    setCustomSelections(prevCustomSelections => newCustomSelections('Strap Colour Accent', e.target.value, prevCustomSelections));
    const newImage = entry.data.custom_strap_colour_accent.find(image => (
      image.name === e.target.value
    ))
    setSelectedStrapColourAccentImage(newImage)
  };

  const handleHardwareColourChange = e => 
  {
    setSelectedHardwareColour(e.target.value);
    setCustomSelections(prevCustomSelections => newCustomSelections('Hardware Colour', e.target.value, prevCustomSelections));
    const newSelectedHardwareColourImage = entry.data.custom_hardware_colour.find(image => (
      image.name === e.target.value
    ))
    setSelectedHardwareColourImage(newSelectedHardwareColourImage)

    let selectedVariantChange = `${selectedSize} / ${e.target.value}`
    let newVariant = data.shopifyProduct?.variants.find(
      variant => variant.title === selectedVariantChange
    )
    setVariantId(newVariant.shopifyId);
  };

  const currency = new Intl.NumberFormat('en-NZ', {
    style: 'currency',
    currency: 'NZD',
    minimumFractionDigits: 2
  })

  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <Header />
      <Breadcrumb 
        category="Dogs" 
        category_url="dogs"
        subcategory="Leads" 
        subcategory_url="leads"
        product={entry.data.heading} 
      />
      <ProductHeader data={entry.data} />
      <ProductCustomHeader props="Customise Yours" />

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/size_duo_white.png" className="w-24 h-24" alt="Width" /> 
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex-none md:flex items-center justify-between pb-6">
                <div className="w-full md:w-8/12">
                  <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Choose size</h2>
                </div>
                <div className="w-full md:w-4/12 text-left md:text-right">
                  <HelperSizeDogLeads name="Size guide" data={data} />
                </div>
              </div>
              {!!selectedVariant && 
                <div>
                  <div className="sr-only">Choose width</div>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left ">
                  {entry.data.custom_width.map((custom,i) => (
                    <label htmlFor={`w_${custom.name}`} key={i} className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                      <input
                        id={`w_${custom.name}`}
                        defaultChecked={custom.name === selectedWidth}
                        type="radio"
                        value={custom.name}
                        onChange={handleWidthChange}
                        name="width"
                        style={{backgroundColor: `${custom.colour}`}}
                        className={custom.name === selectedWidth ? 'w-5 h-5 text-lg text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 text-lg bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                      />
                      {custom.name}
                    </label>
                  ))}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/size_duo_white.png" className="w-24 h-24" alt="Strap" /> 
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex items-center justify-between pb-6">
                <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Choose length</h2>
              </div>
              {!!selectedVariant && 
                <div>
                  <div className="sr-only">Choose length</div>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left ">
                    {selectedLengthOptions.map((name, i) => {
                      return (
                          <label htmlFor={`l_${name}`} key={i} className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                            <input 
                              id={`l_${name}`}
                              checked={name === selectedSize}
                              type="radio"
                              value={name}
                              onChange={handleVariantSizeChange}
                              name="size"
                              className={name === selectedSize ? 'w-5 h-5 font-poppins text-lg bg-orange text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 text-lg bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                            />
                            {name}
                          </label>
                        )
                      }
                    )}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/strap_white.png" className="w-28 h-28" alt="Strap" />  
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex-none md:flex items-center justify-between pb-6">
                <div className="w-full md:w-8/12">
                  <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Choose strap colour - main lead</h2>
                </div> 
                <div className="w-full md:w-4/12 text-left md:text-right">
                  <Helper name="Strapping" data={entry.data.strapping} />
                </div>
              </div>
              {!!selectedVariant && 
                <div className="flex justify-between gap-6">
                  <div className="sr-only">Choose strap colour - main lead</div>
                  <div className="w-6/12 md:w-9/12">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left ">
                      {custom_strap_colour_main_lead.map((custom,i) => (
                        <label htmlFor={`custom_strap_colour_main_lead_${custom.name}`} key={i} className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                          <input
                            id={`custom_strap_colour_main_lead_${custom.name}`}
                            checked={custom.name === selectedStrapColourMainLead}
                            type="radio"
                            value={custom.name}
                            onChange={handleStrapColourMainLeadChange}
                            name="strap_colour_main_lead"
                            style={{backgroundColor: `${custom.colour}`}}
                            className={custom.name === selectedStrapColourMainLead ? 'w-5 h-5 text-lg text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 text-lg bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                          />
                          {custom.name}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className="w-6/12 md:w-3/12">
                    <GatsbyImage className="object-fit w-full h-24 block" image={selectedStrapColourMainLeadImage.image.gatsbyImageData} alt={selectedStrapColourMainLeadImage.image.alt ?? ''} />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/strap_white.png" className="w-28 h-28" alt="Strap" />  
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex-none md:flex items-center justify-between pb-6">
                <div className="w-full md:w-8/12">
                  <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Choose strap colour - accent</h2>
                </div>
                <div className="w-full md:w-4/12 text-left md:text-right">
                  <Helper name="Strapping" data={entry.data.strapping} />  
                </div>
              </div>
              {!!selectedVariant && 
                <div className="flex justify-between gap-6">
                  <div className="sr-only">Choose strap colour - accent</div>
                  <div className="w-6/12 md:w-9/12">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left ">
                      {custom_strap_colour_accent.map((custom,i) => (
                        <label htmlFor={`custom_strap_colour_accent_${custom.name}`} key={i} className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                          <input
                            id={`custom_strap_colour_accent_${custom.name}`}
                            checked={custom.name === selectedStrapColourAccent}
                            type="radio"
                            value={custom.name}
                            onChange={handleStrapColourAccentChange}
                            name="strap_colour_accent"
                            style={{backgroundColor: `${custom.colour}`}}
                            className={custom.name === selectedStrapColourAccent ? 'w-5 h-5 text-lg text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 text-lg bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                          />
                          {custom.name}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className="w-6/12 md:w-3/12">
                    <GatsbyImage className="object-fit w-full h-24 block" image={selectedStrapColourAccentImage.image.gatsbyImageData} alt={selectedStrapColourAccentImage.image.alt ?? ''} />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-12">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/hardware_white.png" className="w-28 h-28" alt="Hardware" />  
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex-none md:flex items-center justify-between pb-6">
                <div className="w-full md:w-8/12">
                  <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Choose hardware</h2>
                </div>
                <div className="w-full md:w-4/12 text-left md:text-right">
                  <Helper name="Hardware" data={entry.data.hardware} />
                </div>
              </div>
              {!!selectedVariant && 
                <div className="flex justify-between gap-6">
                  <div className="sr-only">Choose hardware</div>
                  <div className="w-6/12 md:w-6/12">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 text-left ">
                      {custom_hardware_colour.map((custom,i) => (
                        <label htmlFor={`custom_hardware_colour_${custom.name}`} key={i} className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                          <input
                            id={`custom_hardware_colour_${custom.name}`}
                            checked={custom.name === selectedHardwareColour}
                            type="radio"
                            value={custom.name}
                            onChange={handleHardwareColourChange}
                            name="hardware_colour"
                            style={{backgroundColor: `${custom.colour}`}}
                            className={custom.name === selectedHardwareColour ? 'w-5 h-5 text-lg text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 text-lg bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                          />
                          {custom.name}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className="w-6/12 md:w-6/12">
                    <GatsbyImage className="object-fit w-full block" image={selectedHardwareColourImage.image.gatsbyImageData} alt={selectedHardwareColourImage.image.alt ?? ''} />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/cart_duo_white.png" className="w-24 h-24" alt="" />
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">

              <div className="flex items-center justify-between pb-6">
                <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Add to cart</h2>
              </div>

              <div className="max-w-none prose font-poppins prose-p:text-diamond-80 prose-h2:font-semibold prose-h2:text-diamond prose-h2:mb-3 prose-p:font-medium leading-snug mb-12">
                <h2>Please check your selection</h2>
                <PrismicRichText linkResolver={linkResolver} field={entry.data.info.richText} />
              </div>

              {selectedVariant && (
                <div className="w-full pb-12">
                  <div className="font-poppins max-w-none font-semibold text-diamond text-4xl xl:text-6xl">
                    {selectedVariant.compareAtPriceV2 && selectedVariant.priceV2.amount < selectedVariant.compareAtPriceV2.amount ? (
                      <div className="flex flex-col lg:flex-row gap-0 lg:gap-6">
                        <div className="line-through">{currency.format(selectedVariant.compareAtPriceV2.amount)} NZD</div>
                        <div className="text-orange">{currency.format(selectedVariant.priceV2.amount)} NZD</div>
                      </div>
                    ) : (
                      <>
                        {currency.format(selectedVariant.priceV2.amount)} NZD
                      </>
                    )}
                  </div>
                </div>
              )}

              {!!selectedVariant && (
              <div className="w-full">
                <ProductQuantity formValues={formValues} variantId={selectedVariant.id} customSelections={customSelections} />
              </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ProductSafety data={entry.data} />
      <Footer />
    </>
  )
}

export const query = graphql`
query {
  prismicSizeGuideDogLeads {
    data {
      heading
      text {
        richText
      }
      table_1 {
        col_1
        col_2
        col_3
        col_4
      }
    }
  }
  prismicDogsLeadsTwoToneCarabiner {
    data {
      out_of_stock {
        size
        component
        name
      }
      page_title
      meta_description
      heading
      tag
      description {
        richText
      }
      images {
        image {
          alt
          gatsbyImageData
        }
      }
      safety_and_care_image {
        alt
        gatsbyImageData
      }
      safety_and_care {
        richText
      }
      info {
        richText
      }
      strapping {
        richText
      }
      hardware {
        richText
      }
      custom_width {
        name
      }
      custom_strap_colour_main_lead {
        name
        colour
        image {
          alt
          gatsbyImageData
        }
      }
      custom_strap_colour_accent {
        name
        colour
        image {
          alt
          gatsbyImageData
        }
      }
      custom_hardware_colour {
        name
        colour
        image {
          alt
          gatsbyImageData
        }
      }
    }
  }
  shopifyProduct(handle: {eq: "dogs-leads-two-tone-carabiner"}) {
    id
    priceRangeV2 {
      maxVariantPrice {
        amount
      }
    }
    shopifyId
    storefrontId
    status
    variants {
      id
      price
      shopifyId
      storefrontId
      title
      availableForSale
    }
    options {
      shopifyId
      name
      values
    }
  }
}
`

export default DogsLeadsTwoToneCarabiner

import * as React from "react"
import { graphql, Link } from "gatsby"
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { linkResolver } from '../../../../utils/link_resolver'
import Seo from '../../../../components/seo'
import Footer from '../../../../components/footer'
import Header from '../../../../components/header'
import Helper from '../../../../components/helper'
import HelperSizeDogLeads from '../../../../components/helper_size_dog_leads'
import CartContext from '../../../../components/cart_context';
import ProductQuantity from '../../../../components/product_quantity';
import { useState, useContext, useEffect } from 'react'
import ProductSafety from "../../../../components/product_safety"
import ProductHeader from "../../../../components/product_header"
import ProductCustomHeader from "../../../../components/product_custom_header"
import Validate from '../../../../components/validation.js';
import FieldText from '../../../../components/field_text.js';
import FieldErrorMessage from '../../../../components/field_error_message';
import Breadcrumb from "../../../../components/breadcrumb"

const DogsLeadsLabelled = ({data}) => {
  
  //console.log('data: ', data)
  const entry = data.prismicDogsLeadsLabelled
  const {getProductById} = useContext(CartContext);
  const [product, setProduct] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [variantId, setVariantId] = useState(null);

  const [selectedSize, setSelectedSize] = useState(data.shopifyProduct.options[0].values[0])
  const [selectedWidth, setSelectedWidth] = useState(entry.data.custom_width[0].name)
  
  let selectedWidthName = entry.data.custom_width[0].name.split(/(?<=^\S+)\s/);
  let lengthOptionsArray = data.shopifyProduct.options[0].values.filter((name) => {       
    let nameOption = name.split(/(?<=^\S+)\s/);
    return (
      nameOption[0] === selectedWidthName[0]
    )
  })
  const [selectedLengthOptions, setSelectedLengthOptions] = useState(lengthOptionsArray)

  let custom_strap_colour = getStockLevels(entry.data.custom_strap_colour, 'custom_strap_colour');
  const [selectedStrapColour, setSelectedStrapColour] = useState(custom_strap_colour[0].name)
  const [selectedStrapColourImage, setSelectedStrapColourImage] = useState(custom_strap_colour[0])
  
  let selectedWidthOption = selectedSize.split(/(?<=^\S+)\s/);
  let hardwareOptionsArray = entry.data.custom_hardware_colour.filter((hardware) => {      
    return (hardware.type === selectedWidthOption[0])
  })
  let custom_hardware_colour = getStockLevels(hardwareOptionsArray, 'custom_hardware_colour');
  const [selectedHardwareColour, setSelectedHardwareColour] = useState(custom_hardware_colour[0].name)
  const [selectedHardwareColourImage, setSelectedHardwareColourImage] = useState(custom_hardware_colour[0])
  const [selectedHardwareOptions, setSelectedHardwareOptions] = useState(custom_hardware_colour)

  const [selectedNamePlateFont, setSelectedNamePlateFont] = useState(entry.data.custom_name_plate_font[0].name)
  const [selectedNamePlateGraphic, setSelectedNamePlateGraphic] = useState(entry.data.custom_name_plate_graphic[0].name)
  
  const [formValues, setFormValues] = useState({
    formErrors: '',
    formIsValid: false,
    formControls: {
      name_plate_text_1: {
        type: 'text',
        value: '',
        placeholder: '',
        valid: false,
        touched: false,
        validationRules: {
            isRequired:  true,
            maxLength: '12'
        },
        errorMessages: ''
      },
      name_plate_text_2: {
        type: 'text',
        value: '',
        placeholder: '',
        valid: true,
        touched: false,
        validationRules: {
            maxLength: '12'
        },
        errorMessages: ''
      },
    }
  });
  const [customSelections, setCustomSelections] = useState([
    {
      key: "Width",
      value: selectedWidth
    },
    {
      key: "Length",
      value: selectedSize
    },
    {
      key: "Strap Colour",
      value: selectedStrapColour
    },
    {
      key: "Hardware Colour",
      value: selectedHardwareColour
    },
    {
      key: "Name Plate Text 1",
      value: formValues.formControls.name_plate_text_1.value
    },
    {
      key: "Name Plate Text 2",
      value: formValues.formControls.name_plate_text_2.value
    },
    {
      key: "Name Plate Font",
      value: selectedNamePlateFont
    },
    {
      key: "Name Plate Graphic",
      value: selectedNamePlateGraphic
    }
  ])

  useEffect(() => {
    getProductById(data.shopifyProduct.shopifyId).then(result => {
      setProduct(result);
      if (result) {
        setSelectedVariant(
          result.variants.find(({ id }) => id === variantId) || result.variants[0]
        );
      }
    });
  }, [
    getProductById,
    setProduct,
    data.shopifyProduct.shopifyId,
    variantId,
    selectedWidth,
    selectedSize,
    selectedStrapColour,
    selectedStrapColourImage,
    selectedHardwareColour,
    selectedHardwareColourImage,
    selectedHardwareOptions,
    selectedNamePlateFont,
    selectedNamePlateGraphic,
    customSelections
  ]);

  function getStockLevels(data, component, size) {
    const newStock = data.filter(colour => {
      return !entry.data.out_of_stock.some(stock => {
        if (size === undefined) {
          if (stock.size === selectedSize && stock.component === component) {
            return stock.name === colour.name;
          }
        } else {
          if (stock.size === size && stock.component === component) {
            return stock.name === colour.name;
          }
        }
        return false;
      });
    });
    return newStock;
  }

  function newCustomSelections(component, name, prevCustomSelections) {
    const newCustomSelections = prevCustomSelections.map(customSelection => {
      if (customSelection.key === component) {
        return {...customSelection, value: name};
      }
      return customSelection;
    });
    return newCustomSelections;
  }

  const handleWidthChange = e => 
  {
    // set width
    setSelectedWidth(e.target.value);
    setCustomSelections(prevCustomSelections => newCustomSelections('Width', e.target.value, prevCustomSelections));

    // get length options
    let selectedWidthOption = e.target.value.split(/(?<=^\S+)\s/);
    let lengthOptionsArray = data.shopifyProduct.options[0].values.filter((name) => {       
      let nameOption = name.split(/(?<=^\S+)\s/);
      return (
        nameOption[0] === selectedWidthOption[0]
      )
    })
    setSelectedLengthOptions(lengthOptionsArray)
    setSelectedSize(lengthOptionsArray[0])
    setCustomSelections(prevCustomSelections => newCustomSelections('Length', lengthOptionsArray[0], prevCustomSelections));

    // get hardware options
    let hardwareOptionsArray = entry.data.custom_hardware_colour.filter((hardware) => {      
      return (hardware.type === selectedWidthOption[0])
    })
    let custom_hardware_colour = getStockLevels(hardwareOptionsArray, 'custom_hardware_colour');
    setSelectedHardwareColour(custom_hardware_colour[0].name)
    setSelectedHardwareColourImage(custom_hardware_colour[0])
    setCustomSelections(prevCustomSelections => newCustomSelections('Hardware Colour', custom_hardware_colour[0].name, prevCustomSelections));
    setSelectedHardwareOptions(custom_hardware_colour);

    // set variant_id
    let selectedVariantChange = `${lengthOptionsArray[0]} / ${custom_hardware_colour[0].name}`
    let newVariant = data.shopifyProduct?.variants.find(
      variant => variant.title === selectedVariantChange
    )
    setVariantId(newVariant.shopifyId);

    // get stock for custom strap colour
    custom_strap_colour = getStockLevels(entry.data.custom_strap_colour, 'custom_strap_colour');
    setSelectedStrapColour(custom_strap_colour[0].name)
    setSelectedStrapColourImage(custom_strap_colour[0])
    setCustomSelections(prevCustomSelections => newCustomSelections('Strap Colour', custom_strap_colour[0].name, prevCustomSelections));
  };

  const handleVariantSizeChange = e => 
  {
    // set length
    setSelectedSize(e.target.value);
    setCustomSelections(prevCustomSelections => newCustomSelections('Length', e.target.value, prevCustomSelections));
  
    // get hardware options
    let selectedWidthOption = selectedWidth.split(/(?<=^\S+)\s/);
    let hardwareOptionsArray = entry.data.custom_hardware_colour.filter((hardware) => {      
      return (hardware.type === selectedWidthOption[0])
    })
    let custom_hardware_colour = getStockLevels(hardwareOptionsArray, 'custom_hardware_colour', e.target.value);
    setSelectedHardwareColour(custom_hardware_colour[0].name)
    setSelectedHardwareColourImage(custom_hardware_colour[0])
    setCustomSelections(prevCustomSelections => newCustomSelections('Hardware Colour', custom_hardware_colour[0].name, prevCustomSelections));
    setSelectedHardwareOptions(custom_hardware_colour);

    // get stock for custom strap colour
    let custom_strap_colour = getStockLevels(entry.data.custom_strap_colour, 'custom_strap_colour');
    setSelectedStrapColour(custom_strap_colour[0].name)
    setSelectedStrapColourImage(custom_strap_colour[0])
    setCustomSelections(prevCustomSelections => newCustomSelections('Strap Colour', custom_strap_colour[0].name, prevCustomSelections));
  
    let selectedVariantChange = `${e.target.value} / ${custom_hardware_colour[0].name}`
    let newVariant = data.shopifyProduct?.variants.find(
      variant => variant.title === selectedVariantChange
    )
    setVariantId(newVariant.shopifyId);
  };

  const handleStrapColourChange = e => 
  {
    setSelectedStrapColour(e.target.value);
    setCustomSelections(prevCustomSelections => newCustomSelections('Strap Colour', e.target.value, prevCustomSelections));
    const newSelectedStrapColourImage = entry.data.custom_strap_colour.find(image => (
      image.name === e.target.value
    ))
    setSelectedStrapColourImage(newSelectedStrapColourImage)
  };

  const handleHardwareColourChange = e => 
  {
    setSelectedHardwareColour(e.target.value);
    setCustomSelections(prevCustomSelections => newCustomSelections('Hardware Colour', e.target.value, prevCustomSelections));
    const newImage = selectedHardwareOptions.find(image => (
      image.name === e.target.value
    ))
    setSelectedHardwareColourImage(newImage)

    let selectedVariantChange = `${selectedSize} / ${e.target.value}`
    let newVariant = data.shopifyProduct?.variants.find(
      variant => variant.title === selectedVariantChange
    )
    setVariantId(newVariant.shopifyId);
  };

  const handleNamePlateFontChange = e => {
    setSelectedNamePlateFont(e.target.value);
    const newCustomSelections = customSelections.map(customSelection => {
      if (customSelection.key === 'Name Plate Font') {
        return {...customSelection, value: e.target.value};
      }
      return customSelection;
    });
    setCustomSelections(newCustomSelections)
  };

  const handleNamePlateGraphicChange = e => {
    setSelectedNamePlateGraphic(e.target.value);
    const newCustomSelections = customSelections.map(customSelection => {
      if (customSelection.key === 'Name Plate Graphic') {
        return {...customSelection, value: e.target.value};
      }
      return customSelection;
    });
    setCustomSelections(newCustomSelections)
  };

  const changeFormHandler = e => {
    const name = e.target.name;
    const value = e.target.value;

    const updatedControls = {
      ...formValues.formControls
    };
    const updatedFormElement = {
      ...updatedControls[name]
    };
    updatedFormElement.value = value;
    updatedFormElement.touched = true;

    let validateFormElement = [];
    validateFormElement = Validate(value, updatedFormElement.validationRules);

    updatedFormElement.valid = validateFormElement.isValid;
    updatedFormElement.errorMessages = validateFormElement.errorMessages;

    updatedControls[name] = updatedFormElement;

    let formIsValid = true;
    for (let inputIdentifier in updatedControls) {
      formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
    }

    setFormValues({
      ...formValues,
      formControls: updatedControls,
      formIsValid: formIsValid,
    });

    const newCustomSelections = customSelections.map(customSelection => {
      if (name === 'name_plate_text_1' && customSelection.key === 'Name Plate Text 1') {
        return {...customSelection, value: value};
      }
      if (name === 'name_plate_text_2' && customSelection.key === 'Name Plate Text 2') {
        return {...customSelection, value: value};
      }
      return customSelection;
    });
    setCustomSelections(newCustomSelections)
  }

  const currency = new Intl.NumberFormat('en-NZ', {
    style: 'currency',
    currency: 'NZD',
    minimumFractionDigits: 2
  })

  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <Header />
      <Breadcrumb 
        category="Dogs" 
        category_url="dogs"
        subcategory="Leads" 
        subcategory_url="leads"
        product={entry.data.heading} 
      />
      <ProductHeader data={entry.data} />
      <ProductCustomHeader props="Customise Yours" />

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/size_duo_white.png" className="w-24 h-24" alt="Width" /> 
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex-none md:flex items-center justify-between pb-6">
                <div className="w-full md:w-8/12">
                  <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Choose size</h2>
                </div>
                <div className="w-full md:w-4/12 text-left md:text-right">
                  <HelperSizeDogLeads name="Size guide" data={data} />
                </div>
              </div>
              {!!selectedVariant && 
                <div>
                  <div className="sr-only">Choose width</div>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left ">
                  {entry.data.custom_width.map((custom,i) => (
                    <label htmlFor={`w_${custom.name}`} key={i} className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                      <input
                        id={`w_${custom.name}`}
                        defaultChecked={custom.name === selectedWidth}
                        type="radio"
                        value={custom.name}
                        onChange={handleWidthChange}
                        name="width"
                        style={{backgroundColor: `${custom.colour}`}}
                        className={custom.name === selectedWidth ? 'w-5 h-5 text-lg text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 text-lg bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                      />
                      {custom.name}
                    </label>
                  ))}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/size_duo_white.png" className="w-24 h-24" alt="Strap" /> 
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex items-center justify-between pb-6">
                <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Choose length</h2>
              </div>
              {!!selectedVariant && 
                <div>
                  <div className="sr-only">Choose length</div>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left ">
                    {selectedLengthOptions.map((name, i) => {
                      return (
                          <label htmlFor={`l_${name}`} key={i} className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                            <input 
                              id={`l_${name}`}
                              checked={name === selectedSize}
                              type="radio"
                              value={name}
                              onChange={handleVariantSizeChange}
                              name="size"
                              className={name === selectedSize ? 'w-5 h-5 font-poppins text-lg bg-orange text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 text-lg bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                            />
                            {name}
                          </label>
                        )
                      }
                    )}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/strap_white.png" className="w-28 h-28" alt="Strap" />  
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex-none md:flex items-center justify-between pb-6">
                <div className="w-full md:w-8/12">
                  <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Choose strap colour</h2>
                </div>
                <div className="w-full md:w-4/12 text-left md:text-right">
                  <Helper name="Strapping" data={entry.data.strapping} />
                </div>
              </div>
              {!!selectedVariant && 
                <div className="flex justify-between gap-6">
                  <div className="sr-only">Choose strap colour</div>
                  <div className="w-6/12 md:w-9/12">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left ">
                      {custom_strap_colour.map((custom,i) => (
                        <label htmlFor={`custom_strap_colour${custom.name}`} key={i} className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                          <input
                            id={`custom_strap_colour${custom.name}`}
                            defaultChecked={custom.name === selectedStrapColour}
                            type="radio"
                            value={custom.name}
                            onChange={handleStrapColourChange}
                            name="strap_colour"
                            style={{backgroundColor: `${custom.colour}`}}
                            className={custom.name === selectedStrapColour ? 'w-5 h-5 text-lg text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 text-lg bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                          />
                          {custom.name}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className="w-6/12 md:w-3/12">
                    <GatsbyImage className="object-fit w-full h-24 block" image={selectedStrapColourImage.image.gatsbyImageData} alt={selectedStrapColourImage.image.alt ?? ''} />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-12">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/hardware_white.png" className="w-28 h-28" alt="Hardware" />  
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex-none md:flex items-center justify-between pb-6">
                <div className="w-full md:w-8/12">
                  <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Choose hardware</h2>
                </div>
                <div className="w-full md:w-4/12 text-left md:text-right">
                  <Helper name="Hardware" data={entry.data.hardware} />
                </div>
              </div>
              {!!selectedVariant && 
                <div className="flex justify-between gap-6">
                  <div className="sr-only">Choose hardware</div>
                  <div className="w-6/12 md:w-9/12">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left ">
                      {selectedHardwareOptions.map((custom,i) => (
                        <label htmlFor={`hardware_${custom.name}`} key={i} className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                          <input
                            id={`hardware_${custom.name}`}
                            checked={custom.name === selectedHardwareColour}
                            type="radio"
                            value={custom.name}
                            onChange={handleHardwareColourChange}
                            name="hardware_colour"
                            style={{backgroundColor: `${custom.colour}`}}
                            className={custom.name === selectedHardwareColour ? 'w-5 h-5 text-lg text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 text-lg bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                          />
                          {custom.name}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className="w-6/12 md:w-3/12">
                    <GatsbyImage className="object-fit w-full block" image={selectedHardwareColourImage.image.gatsbyImageData} alt={selectedHardwareColourImage.image.alt ?? ''} />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/personalisation_white.png" className="w-28 h-28" alt="Personalise name plate" />  
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex-none md:flex items-center justify-between pb-6">
                <div className="w-full md:w-8/12">
                  <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Personalise name plate</h2>
                </div>
                <div className="w-full md:w-4/12 text-left md:text-right">
                  <Helper name="Name Plates" data={entry.data.name_plates} />  
                </div>
              </div>
              {!!selectedVariant && 
                <div className="flex-none md:flex justify-between gap-6">
                  <div className="sr-only">Personalisation</div>
                  <div className="w-full md:w-9/12">
                    <div className="flex-none md:flex gap-6">
                      <div className="w-full md:w-6/12">
                        <label htmlFor="name_plate_text_1" className="font-poppins text-2xl font-semibold text-diamond pb-3 block">Add your text <small className="font-italic text-sm">(line 1)</small></label>
                        <FieldText
                          id="name_plate_text_1"
                          name="name_plate_text_1"
                          type={formValues.formControls.name_plate_text_1.type}
                          placeholder={formValues.formControls.name_plate_text_1.placeholder}
                          value={formValues.formControls.name_plate_text_1.value}
                          onChange={changeFormHandler}
                          className={formValues.formControls.name_plate_text_1.touched && !formValues.formControls.name_plate_text_1.valid ? 'w-full px-2 py-1 border-2 border-orange mb-1 focus:border-orange focus:outline-none focus:ring-0' : 'font-poppins font-medium text-diamond-80 w-full px-2 py-1 border-2 border-diamond-50 mb-4 focus:border-diamond-80 focus:outline-none focus:ring-0'}
                        />
                        <FieldErrorMessage
                          className={formValues.formControls.name_plate_text_1.touched && !formValues.formControls.name_plate_text_1.valid ? 'block text-xs text-orange mb-4' : 'hidden'}
                          errorMessages={formValues.formControls.name_plate_text_1.errorMessages}
                        />
                      </div>
                      <div className="w-full md:w-6/12">
                        <label htmlFor="name_plate_text_2" className="font-poppins text-2xl font-semibold text-diamond pb-3 block">Add your text <small className="font-italic text-sm">(line 2)</small></label>
                        <FieldText
                          id="name_plate_text_2"
                          name="name_plate_text_2"
                          type={formValues.formControls.name_plate_text_2.type}
                          placeholder={formValues.formControls.name_plate_text_2.placeholder}
                          value={formValues.formControls.name_plate_text_2.value}
                          onChange={changeFormHandler}
                          className={formValues.formControls.name_plate_text_2.touched && !formValues.formControls.name_plate_text_2.valid ? 'w-full px-2 py-1 border-2 border-orange mb-1 focus:border-orange focus:outline-none focus:ring-0' : 'font-poppins font-medium text-diamond-80 w-full px-2 py-1 border-2 border-diamond-50 mb-4 focus:border-diamond-80 focus:outline-none focus:ring-0'}
                        />
                        <FieldErrorMessage
                          className={formValues.formControls.name_plate_text_2.touched && !formValues.formControls.name_plate_text_2.valid ? 'block text-xs text-orange mb-4' : 'hidden'}
                          errorMessages={formValues.formControls.name_plate_text_2.errorMessages}
                        />
                      </div>
                    </div>

                    <div className="pt-6">
                      <div className="flex items-center justify-between pb-6">
                        <div className="max-w-none prose font-poppins prose-p:text-diamond-80 prose-h2:text-2xl prose-h2:font-semibold prose-h2:text-diamond prose-h2:mb-3 prose-p:font-medium leading-snug">
                          <h2>Choose your font</h2>
                          <PrismicRichText linkResolver={linkResolver} field={entry.data.custom_name_plate_font_text.richText} />
                        </div>
                      </div>
                      <div className="grid grid-cols-3 md:grid-cols-4 gap-3 text-left ">
                        {entry.data.custom_name_plate_font.map((custom, i) => (
                          <label htmlFor={`name_plate_font_${custom.name}`} key={i} className={custom.name === selectedNamePlateFont ? 'flex items-center rounded-xl border-2 border-diamond cursor-pointer' : 'flex items-center border-2 border-transparent cursor-pointer'}>
                            <input
                              id={`name_plate_font_${custom.name}`}
                              defaultChecked={custom.name === selectedNamePlateFont}
                              type="radio"
                              value={custom.name}
                              onChange={handleNamePlateFontChange}
                              name="name_plate_font"
                              className="hidden"
                            />
                            <GatsbyImage className="object-fit w-full block" image={custom.image.gatsbyImageData} alt={custom.image.alt ?? ''} />
                          </label>
                        ))}
                      </div>
                    </div>

                    <div className="pt-12">
                      <div className="flex items-center justify-between pb-6">
                        <h2 className="font-poppins text-2xl font-semibold text-diamond">Choose your graphic</h2>
                      </div>
                      <div className="grid grid-cols-3 md:grid-cols-6 gap-3 text-left ">
                        {entry.data.custom_name_plate_graphic.map((custom, i) => (
                          <label htmlFor={`name_plate_graphic_${custom.name}`} key={i} className={custom.name === selectedNamePlateGraphic ? 'flex items-center px-6 py-3 rounded-xl border-2 border-diamond cursor-pointer' : 'flex items-center px-6 py-3 border-2 border-transparent cursor-pointer'}>
                            <input
                              id={`name_plate_graphic_${custom.name}`}
                              defaultChecked={custom.name === selectedNamePlateGraphic}
                              type="radio"
                              value={custom.name}
                              onChange={handleNamePlateGraphicChange}
                              name="name_plate_graphic"
                              className="hidden"
                            />
                            <GatsbyImage className="object-contain block" image={custom.image.gatsbyImageData} alt={custom.image.alt ?? ''} />
                          </label>
                        ))}
                      </div>
                    </div>

                  </div>
                  <div className="w-full md:w-3/12">
                    &nbsp;
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/cart_duo_white.png" className="w-24 h-24" alt="" />
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">

              <div className="flex items-center justify-between pb-6">
                <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Add to cart</h2>
              </div>

              <div className="max-w-none prose font-poppins prose-p:text-diamond-80 prose-h2:font-semibold prose-h2:text-diamond prose-h2:mb-3 prose-p:font-medium leading-snug mb-12">
                <h2>Please check your selection</h2>
                <PrismicRichText linkResolver={linkResolver} field={entry.data.info.richText} />
              </div>

              {selectedVariant && (
                <div className="w-full pb-12">
                  <div className="font-poppins max-w-none font-semibold text-diamond text-4xl xl:text-6xl">
                    {selectedVariant.compareAtPriceV2 && selectedVariant.priceV2.amount < selectedVariant.compareAtPriceV2.amount ? (
                      <div className="flex flex-col lg:flex-row gap-0 lg:gap-6">
                        <div className="line-through">{currency.format(selectedVariant.compareAtPriceV2.amount)} NZD</div>
                        <div className="text-orange">{currency.format(selectedVariant.priceV2.amount)} NZD</div>
                      </div>
                    ) : (
                      <>
                        {currency.format(selectedVariant.priceV2.amount)} NZD
                      </>
                    )}
                  </div>
                </div>
              )}

              {!!selectedVariant && (
              <div className="w-full">
                <ProductQuantity formValues={formValues} variantId={selectedVariant.id} customSelections={customSelections} />
              </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ProductSafety data={entry.data} />
      <Footer />
    </>
  )
}

export const query = graphql`
query {
  prismicSizeGuideDogLeads {
    data {
      heading
      text {
        richText
      }
      table_1 {
        col_1
        col_2
        col_3
        col_4
      }
    }
  }
  prismicDogsLeadsLabelled {
    data {
      out_of_stock {
        size
        component
        name
      }
      page_title
      meta_description
      heading
      tag
      description {
        richText
      }
      images {
        image {
          alt
          gatsbyImageData
        }
      }
      safety_and_care_image {
        alt
        gatsbyImageData
      }
      safety_and_care {
        richText
      }
      info {
        richText
      }
      strapping {
        richText
      }
      hardware {
        richText
      }
      name_plates {
        richText
      }
      custom_width {
        name
      }
      custom_strap_colour {
        name
        colour
        image {
          alt
          gatsbyImageData
        }
      }
      custom_hardware_colour {
        name
        colour
        type
        image {
          alt
          gatsbyImageData
        }
      }
      custom_name_plate_font {
        name
        image {
          alt
          gatsbyImageData
        }
      }
      custom_name_plate_font_text {
        richText
      }
      custom_name_plate_graphic {
        name
        image {
          alt
          gatsbyImageData
        }
      }
    }
  }
  shopifyProduct(handle: {eq: "dogs-leads-labelled"}) {
    id
    priceRangeV2 {
      maxVariantPrice {
        amount
      }
    }
    shopifyId
    storefrontId
    status
    variants {
      id
      price
      shopifyId
      storefrontId
      title
      availableForSale
    }
    options {
      shopifyId
      name
      values
    }
  }
}
`

export default DogsLeadsLabelled

import * as React from "react"
import { graphql, Link } from "gatsby"
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { linkResolver } from '../../../../utils/link_resolver'
import Seo from '../../../../components/seo'
import Footer from '../../../../components/footer'
import Header from '../../../../components/header'
import HelperSizeDogFeedingMatsAndBowls from '../../../../components/helper_size_guide_dog_feeding_mats_and_bowls'
import CartContext from '../../../../components/cart_context';
import ProductQuantity from '../../../../components/product_quantity';
import { useState, useContext, useEffect } from 'react'
import ProductSafety from "../../../../components/product_safety"
import ProductHeader from "../../../../components/product_header"
import ProductCustomHeader from "../../../../components/product_custom_header"
import Breadcrumb from "../../../../components/breadcrumb"

const DogsMatsAndBowlsFeedingBowl = ({data}) => {
  
  const entry = data.prismicDogsMatsAndBowlsFeedingBowl
  const {getProductById} = useContext(CartContext);
  const [product, setProduct] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [variantId, setVariantId] = useState(null);
  const [selectedSize, setSelectedSize] = useState(data.shopifyProduct.options[0].values[0])
  const [selectedColour, setSelectedColour] = useState(entry.data.custom_colour_combination[0].name)
  const [selectedColourImage, setSelectedColourImage] = useState(entry.data.custom_colour_combination[0])
  const [formValues] = useState({
    formErrors: '',
    formIsValid: true,
  });
  const [customSelections, setCustomSelections] = useState([
    {
      key: "Size",
      value: selectedSize
    },
    {
      key: "Colour",
      value: selectedColour
    },
  ])
  useEffect(() => {
    getProductById(data.shopifyProduct.shopifyId).then(result => {
      setProduct(result);
      if (result) {
        setSelectedVariant(
          result.variants.find(({ id }) => id === variantId) || result.variants[0]
        );
      }
    });
  }, [
    getProductById,
    setProduct,
    data.shopifyProduct.shopifyId,
    variantId,
    selectedColour,
    selectedColourImage,
    customSelections
  ]);

  const handleVariantSizeChange = e => {
    setSelectedSize(e.target.value);
    let selectedVariantChange = `${e.target.value} / ${selectedColour}`
    let newVariant = data.shopifyProduct?.variants.find(
      variant => variant.title === selectedVariantChange
    )
    setVariantId(newVariant.shopifyId);
    const newCustomSelections = customSelections.map(customSelection => {
      if (customSelection.key === 'Size') {
        return {...customSelection, value: e.target.value};
      }
      return customSelection;
    });
    setCustomSelections(newCustomSelections)
  };

  const handleColourChange = e => {
    setSelectedColour(e.target.value);
    let selectedVariantChange = `${selectedSize} / ${e.target.value}`
    let newVariant = data.shopifyProduct?.variants.find(
      variant => variant.title === selectedVariantChange
    )
    setVariantId(newVariant.shopifyId);
    const newCustomSelections = customSelections.map(customSelection => {
      if (customSelection.key === 'Colour') {
        return {...customSelection, value: e.target.value};
      }
      return customSelection;
    });
    setCustomSelections(newCustomSelections)
    const newSelectedColourImage = entry.data.custom_colour_combination.find(image => (
      image.name === e.target.value
    ))
    setSelectedColourImage(newSelectedColourImage)
  };

  const currency = new Intl.NumberFormat('en-NZ', {
    style: 'currency',
    currency: 'NZD',
    minimumFractionDigits: 2
  })

  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <Header />
      <Breadcrumb 
        category="Dogs" 
        category_url="dogs"
        subcategory="Mats & Bowls" 
        subcategory_url="mats-and-bowls"
        product={entry.data.heading} 
      />
      <ProductHeader data={entry.data} />
      <ProductCustomHeader props="Choose Your Bowl" />

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/size_duo_white.png" className="w-24 h-24" alt="Strap" /> 
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex-none md:flex items-center justify-between pb-6">
                <div className="w-full md:w-8/12">
                  <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Choose size</h2>
                </div>
                <div className="w-full md:w-4/12 text-left md:text-right">
                  <HelperSizeDogFeedingMatsAndBowls name="Size guide" data={data} />
                </div>
              </div>
              {!!selectedVariant && 
                <div>
                  <div className="sr-only">Choose size</div>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left ">
                    {data.shopifyProduct.options[0].values.map((name,i) => (
                      <label htmlFor={name} key={i} className="text-sm md:text-base flex items-center gap-3 cursor-pointer font-poppins font-medium text-diamond-80">
                        <input 
                          id={name}
                          defaultChecked={name === selectedSize}
                          type="radio"
                          value={name}
                          onChange={handleVariantSizeChange}
                          name="size"
                          className={name === selectedSize ? 'w-5 h-5 font-poppins text-lg bg-orange text-orange border-none ring-diamond-80 ring-2 ring-offset-2 focus:outine-none focus:ring-diamond-80 focus:outline-offset-0' : 'w-5 h-5 text-lg bg-diamond-10 text-orange border-none focus:outine-none focus:ring-diamond-80 focus:outline-offset-0'}
                        />
                        {name}
                      </label>
                    ))}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/ethos_white.png" className="w-28 h-28" alt="Strap" />  
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex items-center justify-between pb-6">
                <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Choose colour combination</h2>
              </div>
              {!!selectedVariant && 
                <div className="flex justify-between gap-6">
                  <div className="sr-only">Choose colour combination</div>
                  <div className="w-6/12 md:w-9/12">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left ">
                      {entry.data.custom_colour_combination.map((custom,i) => (
                        <label htmlFor={`custom_colour_${custom.name}`} key={i} className={custom.name === selectedColour ? 'text-sm md:text-base flex items-center px-6 py-3 rounded-xl border-2 border-diamond cursor-pointer' : 'text-sm md:text-base flex items-center px-6 py-3 border-2 border-transparent cursor-pointer'}>
                          <input
                            id={`custom_colour_${custom.name}`}
                            defaultChecked={custom.name === selectedColour}
                            type="radio"
                            value={custom.name}
                            onChange={handleColourChange}
                            name="colour"
                            className="hidden"
                          />
                          <div className="w-full block">
                            <p className="font-poppins font-medium text-diamond-80 leading-tight mb-6">{custom.name}</p>
                            <div className="flex justify-center">
                              <div style={{backgroundColor: `${custom.colour}`}} className="w-16 h-8"></div>
                              <div style={{backgroundColor: `${custom.colour2}`}} className="w-16 h-8"></div>
                            </div>
                          </div>
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className="w-6/12 md:w-3/12">
                    <GatsbyImage className="object-fit w-full block" image={selectedColourImage.image.gatsbyImageData} alt={selectedColourImage.image.alt ?? ''} />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="w-full pb-24">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between gap-12">
            <div className="w-full md:w-2/12">
              <div className="bg-diamond rounded-full w-36 h-36 flex items-center justify-center">
                <img src="/img/cart_duo_white.png" className="w-24 h-24" alt="" />
              </div>
            </div>
            <div className="w-full md:w-10/12 pt-6 md:pt-0">
              <div className="flex items-center justify-between pb-6">
                <h2 className="font-poppins font-semibold text-2xl md:text-3xl text-orange">Add to cart</h2>
              </div>
              <div className="max-w-none prose font-poppins prose-p:text-diamond-80 prose-h2:font-semibold prose-h2:text-diamond prose-h2:mb-3 prose-p:font-medium leading-snug mb-12">
                <h2>Please check your selection</h2>
                <PrismicRichText linkResolver={linkResolver} field={entry.data.info.richText} />
              </div>
              
              {selectedVariant && (
                <div className="w-full pb-12">
                  <div className="font-poppins max-w-none font-semibold text-diamond text-4xl xl:text-6xl">
                    {selectedVariant.compareAtPriceV2 && selectedVariant.priceV2.amount < selectedVariant.compareAtPriceV2.amount ? (
                      <div className="flex flex-col lg:flex-row gap-0 lg:gap-6">
                        <div className="line-through">{currency.format(selectedVariant.compareAtPriceV2.amount)} NZD</div>
                        <div className="text-orange">{currency.format(selectedVariant.priceV2.amount)} NZD</div>
                      </div>
                    ) : (
                      <>
                        {currency.format(selectedVariant.priceV2.amount)} NZD
                      </>
                    )}
                  </div>
                </div>
              )}

              <div className="w-full">
                {!!selectedVariant && (
                  <>
                    {selectedVariant.available ? 
                      <ProductQuantity formValues={formValues} variantId={selectedVariant.id} customSelections={customSelections} />
                    : 
                    <div className="flex-1 border border-transparent py-3 px-8 flex items-center justify-center text-base text-white bg-red w-full">
                      Sold out
                    </div>
                    }
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ProductSafety data={entry.data} />
      <Footer />
    </>
  )
}

export const query = graphql`
query {
  prismicSizeGuideDogFeedingMatsAndBowls {
    data {
      heading
      text {
        richText
      }
      table_1 {
        col_1
        col_2
        col_3
        col_4
      }
      table_2 {
        col_1
        col_2
        col_3
      }
    }
  }
  prismicDogsMatsAndBowlsFeedingBowl {
    data {
      page_title
      meta_description
      heading
      description {
        richText
      }
      images {
        image {
          alt
          gatsbyImageData
        }
      }
      safety_and_care_image {
        alt
        gatsbyImageData
      }
      safety_and_care {
        richText
      }
      info {
        richText
      }
      custom_colour_combination {
        name
        colour
        colour2
        image {
          alt
          gatsbyImageData
        }
      }
    }
  }
  shopifyProduct(handle: {eq: "dogs-mats-and-bowls-feeding-bowl"}) {
    id
    priceRangeV2 {
      maxVariantPrice {
        amount
      }
    }
    shopifyId
    storefrontId
    status
    variants {
      id
      price
      shopifyId
      storefrontId
      title
      availableForSale
    }
    options {
      shopifyId
      name
      values
    }
  }
}
`

export default DogsMatsAndBowlsFeedingBowl
